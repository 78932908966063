
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as alertifyjs from "alertifyjs";
import { ServiceapiService } from "src/app/serviceapi.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface RowData {
  select: string;
  id: string;
  exFeeName: string;
  exAmount: string;
}

@Component({
  selector: 'app-updatestudentphoto',
  templateUrl: './updatestudentphoto.component.html',
  styleUrls: ['./updatestudentphoto.component.scss']
})
export class UpdatestudentphotoComponent implements OnInit {
  forms: FormGroup;
  userDetails: any;
  loading5: boolean = false;
  loading6: boolean = false;
  load: boolean = false;
  isLoading: boolean = false;
  saveBtn: boolean = true;
  rows: any = [];
  temp: any = [];
  id: any = null;
  uid: any;
  row_department = new Array();
  degreeLists = new Array();
  programList= new Array();
  academicyearLists= new Array();
  studentDetailsList= new Array();
  courseLists= new Array();
  row_studentformat= new Array();
  getStartVal: any;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  public displayedColumns = ['college','degree', 'academicYear', 'programme', 'format', 
  'status'];
  dataSourceTable: MatTableDataSource<RowData>;
  @ViewChild("paginatorTable") paginatorTable: MatPaginator;
  @ViewChild("matSort") sortTable: MatSort;

  collegeDetails:any;
  degreeDetails:any;
  programDetails:any;
  academicyearDetails:any;
  programyearDetails:any;
  semesterDetails:any;
  collegeName:any;
  degreeName:any;
  prpgramName:any;
  academicyearName:any;
  programyearName:any;
  semesterName:any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private serviceapi: ServiceapiService
  ) {
    this.forms = this.formBuilder.group({
      coursefile: new FormControl("", Validators.required),
    });
  }
  collegeList = new Array();
  degreeList = new Array();
  ngOnInit() {
    this.userDetails = localStorage.getItem("currentUser");
 
  }



  //upload function
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.forms.get('coursefile')?.setValue(file);
    }
  }


uploadStudentPhoto(){
  this.loading6 = true;
  this.serviceapi.uploadStudentPhoto(
    this.forms.get("coursefile")?.value,
   JSON.parse(this.userDetails)[0].studentId,
    'student',
    JSON.parse(this.userDetails)[0].id
  ).subscribe((data) => {
    this.rows = data;
    if (this.rows.status == 1) {
      alertifyjs.set('notifier', 'position', 'top-right');
      alertifyjs.success( this.rows.message);
      this.loading6 = false;
      this.forms.reset()
    } else {
      alertifyjs.set('notifier', 'position', 'top-right');
      alertifyjs.error( this.rows.message);
      this.loading6 = false;
    }
  });
}


}
