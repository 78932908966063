import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServiceapiService {
  constructor(private http: HttpClient) { }

  //login
  login(userName: string, password: string, loginType: string) {
    return this.http
      .post<any>(environment.endpoint + "/login/doLogin", {
        username: userName,
        password: btoa(password),
        loginType: loginType,
      })

      .pipe(
        map((user) => {
       
          if (user.status == 1) {
          
            localStorage.setItem("currentUser", JSON.stringify(user));
          }
          return user;
        })
      );
  }

  //degree API
  forgetpassword(
    usernme: any,
    logintype: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/forgetPassword",
      {
        username: usernme,
        loginType: logintype
      },
      { observe: "response" }
    );
  }

  //college API
  colleges(
    type: any,
    collegename: any,
    email: any,
    identifier: any,
    telephone: any,
    fax: any,
    place: any,
    address: any,
    active: any,
    locallang: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/colleges/" + type,
      {
        exName: collegename,
        exEmail: email,
        exIdentifier: identifier,
        exTelephone: telephone,
        exFax: fax,
        exPlace: place,
        exAddress: address,
        exIsActive: active,
        exLocalLangName: locallang,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //degree API
  degree(
    type: any,
    exaname: any,
    desc: any,
    uid: any,
    active: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/degree/" + type,
      {
        exName: exaname,
        exDescription: desc,
        id: uid,
        exIsActive: active,
        user: user,
      },
      { observe: "response" }
    );
  }

  //role API
  role(
    type: any,
    rolename: any,
    active: any,
    user: any,
    navigatingurl: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "Role",
      {
        exRoleName: rolename,
        exIsActive: active,
        user: user,
        exNavigatingURL: navigatingurl
      },
      { observe: "response" }
    );
  }

  //alert category API
  alertcategory(
    type: any,
    category: any,
    uid: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/notification/category/" + type,
      {
        category: category,
        id: uid,
      },
      { observe: "response" }
    );
  }

  //sms utility API
  getTypeforUtility(
    id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/utility/getMessagesTypeOnUserType",
      {
        id: id
      },
      { observe: "response" }
    );
  }

  getHostelIncharge(

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getUsers",
      {

      },
      { observe: "response" }
    );
  }

  getPhotoGallerydetails(

    ): Observable<HttpResponse<any[]>> {
      return this.http.post<any[]>(
        environment.endpoint + "/masters/getDashboardGallery",
        {
  
        },
        { observe: "response" }
      );
    }

    deletegallerydetails(
      id:any
      ): Observable<HttpResponse<any[]>> {
        return this.http.post<any[]>(
          environment.endpoint + "/masters/deleteDashboardGallery",
          {
    id:id
          },
          { observe: "response" }
        );
      }


  //donor API
  donormaster(
    type: any,
    donorname: any,
    donornameink: any,
    donoraddress: any,
    mobileno: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/convocation/" + type + "DonorDetails",
      {
        donarName: donorname,
        donorNameInKannada: donornameink,
        donorAddress: donoraddress,
        mobileNumber: mobileno,
        description: desc,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //donor API
  hostelMaster(
    type: any,
    college: any,
    degree: any,
    hostelname: any,
    hosteltype: any,
    hostelcode: any,
    hostelcapacity: any,
    hostelinchrge: any,
    hosteladdress: any,
    telephone: any,
    exactive: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "Hostel",
      {
        colleges: college,
        degree: degree,
        hostelName: hostelname,
        hostelType: hosteltype,
        hostelCode: hostelcode,
        hostelCapacity: hostelcapacity,
        hostelIncharge: hostelinchrge,
        hostelAddress: hosteladdress,
        telephone: telephone,
        exIsActive: exactive,
        user: user,
        id: id
      },
      { observe: "response" }
    );
  }


  //donor API
  hostelFeetype(
    type: any,
    feename: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "HostelFeeType",
      {
        exFeeName: feename,
        exDescription: desc,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //donor API
  Feetype(
    type: any,
    feename: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/feetype/" + type,
      {
        exFeeName: feename,
        exDescription: desc,
        exIsActive: active,
        user: user,
        id: id
      },
      { observe: "response" }
    );
  }

  //donor API
  refund(
    orderid: any,
    feeid: any,
    user: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/refundPayment",
      {
        orderId: orderid,
        feeId: feeid,
        user: user
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  getStudentDetailsforhostel(
    college: any,
    academicyear: any,
    degree: any,
    program: any,
    programyear: any,
    semester: any,
    hostel: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getHostelStudentDetails",
      {
        college: college,
        academicYear: academicyear,
        degree: degree,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
        hostel: hostel,
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  submitHostelConfig(
    college: any,
    academicyear: any,
    degree: any,
    program: any,
    semester: any,
    hostel: any,
    hostelfeetype: any,
    student: any,
    amount: any,
    feename: any,
    stratdate: any,
    enddate: any,
    month: any,
    active: any,
    programyear: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/saveHostelConfiguration",
      {
        college: college,
        academicYear: academicyear,
        degree: degree,
        programmes: program,
        semester: semester,
        hostel: hostel,
        hostelfeetype: hostelfeetype,
        student: student,
        amount: amount,
        feename: feename,
        startDate: stratdate,
        endDate: enddate,
        monthName: month,
        exIsActive: active,
        programmeYear: programyear,
        user: user
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  allocateHostel(
    college: any,
    academicyear: any,
    degree: any,
    program: any,
    hostel: any,
    student: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateHostelForStudent",
      {
        colleges: college,
        academicyear: academicyear,
        degree: degree,
        programmes: program,
        hostel: hostel,
        studentList: student,
        user: user
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  submitinternal(
    academicyear: any,
    course: any,
    degree: any,
    studentlist: any,
    program: any,
    college: any,
    programyear: any,
    semester: any,
    staff: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/submitInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        degree: degree,
        studentList: studentlist,
        programmes: program,
        colleges: college,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
      },
      { observe: "response" }
    );
  }


  //studentdetails API
  submitinternalcomponent(
    academicyear: any,
    course: any,
    degree: any,
    program: any,
    college: any,
    staff: any,
    programyear: any,
    semester: any,
    studentlist: any,
   component:any 
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/submitInternalMarksForComponent",
      {
        academicYear: academicyear,
        courses: course,
        degree: degree,
        programmes: program,
        colleges:college,
        staff:staff,
        programmeyear:programyear,
        semster:semester,
        studentList :studentlist,
        component:component
      },
      { observe: "response" }
    );
  }

//studentdetails API
getcomponentdetails(
  academicyear: any,
  course: any,
  degree: any,
  program: any,
  college: any,
  programyear: any,
  semester: any,
  componenetma:any,
  section:any
): Observable<HttpResponse<any[]>> {
  return this.http.post<any[]>(
    environment.endpoint + "/academic/getComponentMarks",
    {
      academicYear: academicyear,
      course: course,
      degree: degree,
      programme: program,
      college:college,
      programYear:programyear,
      semester:semester,
      exComponentMaster:componenetma,
      section:section
    },
    { observe: "response" }
  );
}

getcomponentdetailsSubmit(
  academicyear: any,
  course: any,
  degree: any,
  program: any,
  programyear: any,
  componenetma:any,
  semester: any,
  college:any
): Observable<HttpResponse<any[]>> {
  return this.http.post<any[]>(
    environment.endpoint + "/academic/getStudentDetails",
    {
      academicYear: academicyear,
    course: course,
    degree: degree,
    programme: program,
    programYear: programyear,
    exComponentMaster: componenetma,
   semester:semester,
   college:college
    },
    { observe: "response" }
  );
}

getcomponentdetailsforadmin(
  academicyear: any,
  course: any,
  degree: any,
  program: any,
  college: any,
  programyear: any,
  semester: any,
 
): Observable<HttpResponse<any[]>> {
  return this.http.post<any[]>(
    environment.endpoint + "/academic/getComponentMarksForAdmin",
    {
      academicYear: academicyear,
      course: course,
      degree: degree,
      programme: program,
      college:college,
      programYear:programyear,
      semester:semester
    },
    { observe: "response" }
  );
}

//studentdetails API
submitmanualinternlamarks(
  academicyear: any,
  course: any,
  degree: any,
  studentid:any,
  program: any,
  college: any,
  marks:any,
  remark:any,
  staff:any,
  programyear: any,
  semester: any,
  componentmaster:any
): Observable<HttpResponse<any[]>> {
  return this.http.post<any[]>(
    environment.endpoint + "/academic/updateComponentMarks",
    {
      academicYear: academicyear,
      course: course,
      degree: degree,
      studentId: studentid,
      programme: program,
      college:college,
      marks:marks,
      reMarks:remark,
      staff:staff,
      programYear:programyear,
      semester:semester,
      exComponentMaster:componentmaster
    },
    { observe: "response" }
  );
}

  //studentdetails API
  submitgrade(
    college: any,
    degree: any,
    academicyear: any,
    program: any,
    course: any,
    programyear: any,
    semester: any,
    student: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/submitStudentGradeForPG",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmes: program,
        courses: course,
        programmeYear: programyear,
        semester: semester,
        exStudentIds: student,
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  updatemarks(
    academicyear: any,
    course: any,
    degree: any,
    studentlist: any,
    program: any,
    college: any,
    remark: any,
    programyear: any,
    semester: any,
    staff: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/saveInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        degree: degree,
        exStudentId: studentlist,
        programmes: program,
        colleges: college,
        exRemarks: remark,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
      },
      { observe: "response" }
    );
  }

    //studentdetails API
    updatecomponent(
      academicyear: any,
      course: any,
      degree: any,
      studentlist: any,
      program: any,
      college: any,
      averaage:any,
      remark: any,
      staff: any,
      programyear: any,
      semester: any,
     componentmaster:any,
     studentname:any
    ): Observable<HttpResponse<any[]>> {
      return this.http.post<any[]>(
        environment.endpoint + "/academic/saveComponentMarks",
        {
          academicYear: academicyear,
          course: course,
          degree: degree,
          studentId: studentlist,
          programme: program,
          college:college,
          marks:averaage,
         reMarks:remark,
          staff:staff,
         programYear:programyear,
          semester:semester,
         exComponentMaster:componentmaster,
         studentName:studentname
        },
        { observe: "response" }
      );
    }

  //studentdetails API
  updatemarkspg(
    remark: any,
    id: any,
    staff: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentGradeByStaffIdForPg",
      {
        exRemarks: remark,
        id: id,
        staff: staff
      },
      { observe: "response" }
    );
  }


  updategrades(
    id: any,
    gradepoint: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentCredit",
      {
        id: id,
        exGradePoint: gradepoint,
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  updatelist(
    academicyear: any,
    course: any,
    degree: any,
    averaage: any,
    studentlist: any,
    program: any,
    college: any,
    programyear: any,
    semester: any,
    staff: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/saveInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        degree: degree,
        exAverage: averaage,
        exStudentId: studentlist,
        programmes: program,
        colleges: college,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
   
      },
      { observe: "response" }
    );
  }

    //studentdetails API
    updatelistcomponent(
      academicyear: any,
      course: any,
      degree: any,
      studentlist: any,
      program: any,
      college: any,
      averaage: any,
      remark:any,
      staff: any,
      programyear: any,
      semester: any,
     component:any,
     stud:any
    ): Observable<HttpResponse<any[]>> {
      return this.http.post<any[]>(
        environment.endpoint + "/academic/saveComponentMarks",
        {
          academicYear: academicyear,
          course: course,
          degree: degree,
          studentId: studentlist,
          programme: program,
          college:college,
          marks:averaage,
          reMarks:remark,
          staff:staff,
          programYear:programyear,
          semester:semester,
          exComponentMaster:component,
          studentName:stud
        },
        { observe: "response" }
      );
    }

  //studentdetails API
  updatelistpg(
    gradepoint: any,
    id: any,
    staff: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentGradeByStaffIdForPg",
      {
        exGradePoint: gradepoint,
        id: id,
        staff: staff,
      },
      { observe: "response" }
    );
  }

  updategradeforpg(
    gradepoint: any,
    id: any,
    remark: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentGradeForPg",
      {
        exGradePoint: gradepoint,
        id: id,
        exRemarks: remark,
        user: user
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  deallocateHostel(
    academicyear: any,
    college: any,
    program: any,
    degree: any,
    student: any,
    hostel: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/HostelDeAllocationForStudent",
      {
        academicyear: academicyear,
        colleges: college,
        programmes: program,
        degree: degree,
        studentList: student,
        hostel: hostel,
        user: user
      },
      { observe: "response" }
    );
  }

  //college update API
  updateCollege(
    college: any,
    student: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/updateCollege",
      {
        colleges: college,
        studentId: student,
        user: user
      },
      { observe: "response" }
    );
  }


  //studentdetails API
  sendNotification(
    category: any,
    college: any,
    programyear: any,
    academicyear: any,
    semester: any,
    templateid: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/notification/alert/create",
      {
        categoryId: category,
        colleges: college,
        programmeYear: programyear,
        academicYear: academicyear,
        semester: semester,
        templateId: templateid,
        user: user,
        id: id
      },
      { observe: "response" }
    );
  }

  loadmarks(
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getComponentMarksById",
      {
       id:id
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  submitStudentSMS(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    usertype: any,
    messagetype: any,
    academicyear: any,
    isbulk: any,
    mobile: any,
    message: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/utility/sendSMS",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        programmeYear: programyear,
        userType: usertype,
        messageType: messagetype,
        academicYear: academicyear,
        isBulk: isbulk,
        mobileNumber: mobile,
        messageBody: message
      },
      { observe: "response" }
    );
  }

  //studentdetails API
  submitReason(
    studentid: any,
    desc: any,
    bankrefno: any,
    tnxrefno: any,
    complaintype: any,
    academicyear: any,
    program: any,
    programyear: any,
    college: any,
    semester: any,
    degree: any,
    orderid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/registerComplain",
      {
        studentId: studentid,
        exDescription: desc,
        bankReferenceNo: bankrefno,
        txnReferenceNo: tnxrefno,
        complaintType: complaintype,
        academicYear: academicyear,
        programmes: program,
        programmeyear: programyear,
        colleges: college,
        semester: semester,
        degree: degree,
        orderId: orderid
      },
      { observe: "response" }
    );
  }


  //feedbackscale API
  feedbackscale(
    type: any,
    exname: any,
    scalevalue: any,
    desc: any,
    degree: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "Scale",
      {
        exName: exname,
        scaleValue: scalevalue,
        exDescription: desc,
        degree: degree,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //feedbackscale API
  feedbacksabout(
    type: any,
    exname: any,
    scale: any,
    degree: any,
    types: any,
    desc: any,
    isactive: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "AboutFeedBack",
      {
        exName: exname,
        scale: scale,
        degree: degree,
        type: types,
        exDescription: desc,
        exIsActive: isactive,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //qptype API
  qptype(
    type: any,
    qptype: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "QuestionPaperType",
      {
        exQpType: qptype,
        exDescription: desc,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //qptype API
  qptopic(
    type: any,
    qptopic: any,
    desc: any,
    identifier: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/QuestionPaperTopic/" + type,
      {
        exQpTopic: qptopic,
        exDescription: desc,
        exIdentifier: identifier,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //category API
  category(
    type: any,
    exaname: any,
    uid: any,
    active: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/category/" + type,
      {
        exCategoryName: exaname,
        id: uid,
        exIsActive: active,
        user: user,
      },
      { observe: "response" }
    );
  }

  getconvocationdata(
    id: any

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/convocation/getStudentDetailsforConvocation",
      {
        admissionID: id
      },
      { observe: "response" }
    );
  }

  //academic year API
  academicyear(
    type: any,
    academicyear: any,
    academiclocallang: any,
    desc: any,
    startdate: any,
    enddate: any,
    active: any,
    user: any,
    uid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/academicYear/" + type,
      {
        exYear: academicyear,
        exYearInLocalLang: academiclocallang,
        exDescription: desc,
        exStartdate: startdate,
        exEnddate: enddate,
        exIsActive: active,
        user: user,
        id: uid,
      },
      { observe: "response" }
    );
  }

  //academic year API
  getStudentCount(
    academicyear: any,
    degree:any,
    college:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/studentcount",
      {
        academicyear: academicyear,
        degree: degree,
        colleges: college
      },
      { observe: "response" }
    );
  }

  getStudentCountprogram(
    academicyear: any,
    college: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/studentcountOnProgram",
      {
        academicYear: academicyear,
        colleges: college,
        degree: degree
      },
      { observe: "response" }
    );
  }

  getStudentCountgender(
    user: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/studentcountOnGender",
      {
        user: user,

      },
      { observe: "response" }
    );
  }


  getStudentCounttrasaction(
    academicyear: any,
    degree:any,
    college:any,
    programyear:any,
    semester:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/transactionCountOnDay",
      {
        academicYear:academicyear,
        degree:degree,
        colleges:college,
        programmeYear:programyear,
        semesters:semester
      },
      { observe: "response" }
    );
  }


  getStudentCountcomplaintticket(
    user: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/getcomplaintTicket",
      {
        user: user,

      },
      { observe: "response" }
    );
  }

  getStudentCountonbooklet(
    academicyear: any,
    degree:any,
    college:any,
    programyear:any,
    semester:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/evaluationCount",
      {
        academicYear:academicyear,
        degree:degree,
        colleges:college,
        programmeYear:programyear,
        semesters: semester
      },
      { observe: "response" }
    );
  }

  getStudentCountonregistration(
    academicyear: any,
    degree:any,
    college:any,
    programyear:any,
    semester:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/studentRegistrationCount",
      {
        academicYear:academicyear,
        degree:degree,
        colleges:college,
        programmeYear:programyear,
        semesters: semester

      },
      { observe: "response" }
    );
  }

  getStudentCountgrade(
    academicyear: any,
    degree:any,
    college:any,
    programyear:any,
    semester:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/studentcountOnGradePoint",
      {
        academicYear:academicyear,
        degree:degree,
        colleges:college,
        programmeYear:programyear,
        semesters:semester

      },
      { observe: "response" }
    );
  }


  //reasons year API
  reasons(

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/getComplaintType",
      {

      },
      { observe: "response" }
    );
  }

  //program API
  programs(
    type: any,
    colleges: any,
    names: any,
    duration: any,
    degree: any,
    desc: any,
    active: any,
    identifier: any,
    locallang: any,
    user: any,
    id: any,
    programtype:any,
    programvalue:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/programme/" + type,
      {
        colleges: colleges,
        exName: names,
        exDuration: duration,
        degree: degree,
        exDescription: desc,
        exIsActive: active,
        exIdentifier: identifier,
        exLocalLangName: locallang,
        user: user,
        id: id,
        programType:programtype,
        programValue:programvalue
      },
      { observe: "response" }
    );
  }

  //program API
  users(
    type: any,
    colleges: any,
    degree: any,
    empid: any,
    username: any,
    password: any,
    mobile: any,
    email: any,
    active: any,
    role: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "User",
      {
        colleges: colleges,
        degree: degree,
        employeeId: empid,
        exUserName: username,
        exPassword: password,
        exMobile: mobile,
        exEmail: email,
        exIsActive: active,
        role: role,
        user: user,
        id: id

      },
      { observe: "response" }
    );
  }

  users1(
    type: any,
    colleges: any,
    degree: any,
    empid: any,
    username: any,
    password: any,
    mobile: any,
    email: any,
    active: any,
    role: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deleteUsers",
      {
        colleges: colleges,
        degree: degree,
        employeeId: empid,
        exUserName: username,
        exPassword: password,
        exMobile: mobile,
        exEmail: email,
        exIsActive: active,
        role: role,
        user: user,
        id: id

      },
      { observe: "response" }
    );
  }


  //notification template API
  notificationTemplate(
    type: any,
    category: any,
    message: any,
    title: any,
    uid: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/notification/template/" + type,
      {
        categoryId: category,
        message: message,
        title: title,
        id: uid
      },
      { observe: "response" }
    );
  }

  //notification template API
  studentsforUtility(
    acdemicyear: any,
    programyear: any,
    program: any,
    college: any,
    degree: any,
    semester: any,
    usertyep: any,
    messagetype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/utility/getMobileNumber",
      {
        academicYear: acdemicyear,
        programmeYear: programyear,
        programmes: program,
        colleges: college,
        degree: degree,
        semester: semester,
        userType: usertyep,
        messageType: messagetype
      },
      { observe: "response" }
    );
  }

  //program on degree API
  programOnDegree(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/programmesOnDegree",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  //exam on academicyear API
  examonacademicyear(academic: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getAnnouncementDetails",
      {
        academicYear: academic,
      },
      { observe: "response" }
    );
  }

  hostelbasedonCD(college: any, degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/HostelOnCollegeAndDegree",
      {
        clg: college,
        degre: degree,
      },
      { observe: "response" }
    );
  }

  //program on degree API
  getStudentIds(
    academicyear: any,
    degree: any,
    program: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint +
      "/convocation/getstudentOnDegreeAndCollegeAndProgrammeAndAcademicyear",
      {
        academicyear: academicyear,
        programmes: program,
        degree: degree,
        colleges: college,
      },
      { observe: "response" }
    );
  }

  getAllDonors(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/convocation/getDonorDetails",
      {},
      { observe: "response" }
    );
  }

  //staff API
  staffonCD(college: any, degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/staffOnCollegeAndDegree",
      {
        college: college,
        degree: degree,
      },
      { observe: "response" }
    );
  }

  //update student photo API
  getStudentforUpdatePhoto(
    college: any,
    degree: any,
    academicyear: any,
    programid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/studentOnCollegeAndDegreeProAndAcdYear",
      {
        college: college,
        degree: degree,
        academicYear: academicyear,
        id: programid,
      },
      { observe: "response" }
    );
  }

  //discipline API
  discipline(
    type: any,
    uname: any,
    active: any,
    program: any,
    identifier: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/discipline/" + type,
      {
        exName: uname,
        exIsActive: active,
        programmes: program,
        exIdentifier: identifier,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  programyear(
    type: any,
    uname: any,
    desc: any,
    active: any,
    lang: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/programmeYear/" + type,
      {
        exName: uname,
        exDescription: desc,
        exIsActive: active,
        exLocalLangName: lang,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  semester(
    type: any,
    uname: any,
    lang: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/semster/" + type,
      {
        exName: uname,
        exLocalLangName: lang,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  

  getdocumentofstudent(
    studentid: any,
    documenttype: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getUplodedDocument",
      {
        studentId: studentid,
        documentType: documenttype
      },
      { observe: "response" }
    );
  }

  documenttype(

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getDocumetType",
      {

      },
      { observe: "response" }
    );
  }

  studentmembelist(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/studentMemberListForForm1",
      {
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  studentchoosenexternalexaminer(
    programyearr: any,
    academicyear: any,
    semester: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkStudentChoosenExternalExaminer",
      {
        programmeYear: programyearr,
        academicYear: academicyear,
        semester: semester,
        studentId: studentid,
      },
      { observe: "response" }
    );
  }

  getcoursesforexamier(
    programyearr: any,
    academicyear: any,
    semester: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/externalExaminerPgList",
      {
        programmeYear: programyearr,
        academicYear: academicyear,
        semester: semester,
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  checkform4approval(
    admission: any,
    programyearr: any,
    academicyear: any,
    program: any,
    semester: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkForm4Approval",
      {
        admission: admission,
        programmeYear: programyearr,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degree
      },
      { observe: "response" }
    );
  }

  deanapproveform5(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/studentMemberListForForm1",
      {
        studentId: studentid
      },
      { observe: "response" }
    );
  }
  deanapproveform4(
    semester: any,
    programyear: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanapproveForm4",
      {
        semester: semester,
        programmeYear: programyear,
        studentId: studentid
      },
      { observe: "response" }
    );
  }
  registrarapproveform4(
    semester: any,
    programyear: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm4",
      {
        semester: semester,
        programmeYear: programyear,
        studentId: studentid
      },
      { observe: "response" }
    );
  }
  dieandisapproveform4(
    semester: any,
    programyear: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm4",
      {
        semester: semester,
        programmeYear: programyear,
        studentId: studentid
      },
      { observe: "response" }
    );
  }
  registrardisapproveform4(
    semester: any,
    programyear: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm4",
      {
        semester: semester,
        programmeYear: programyear,
        studentId: studentid
      },
      { observe: "response" }
    );
  }
  deanapproveform3(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanapproveForm3",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  registrarapproveform3(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm3",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  registrarpproveform5(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm5",
      {
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  deandisarpproveform5(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm5",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  deandisarpproveform3(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm3",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  registrardisarpproveform5(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm5",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  registrardisarpproveform3(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm3",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }

  deanapproveform6(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanapproveForm6",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  registrarapproveform6(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm6",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  deandisapproveform6(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm6",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  disapprovedeanform1(
    semester: any,
    programyear: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm1",
      {
        semester: semester,
        programmeYear: programyear,
        admission: admission
      },
      { observe: "response" }
    );
  }

  deanapproveform1(
    semester: any,
    programyear: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanapproveForm1",
      {
        semester: semester,
        programmeYear: programyear,
        admission: admission
      },
      { observe: "response" }
    );
  }
  registrarapproveform1(
    semester: any,
    programyear: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm1",
      {
        semester: semester,
        programmeYear: programyear,
        admission: admission
      },
      { observe: "response" }
    );
  }

  studentregistredcourseornot(
    studentid: any,
    semester: any,
    programyear: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkStudentRegisteredCoursesOrNot",
      {
        exStudentId: studentid,
        semester: semester,
        programmeyear: programyear,
      },
      { observe: "response" }
    );
  }
  getcousrelistforform(
    studentid: any,
    semester: any,
    programyear: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCoursesList",
      {
        exStudentId: studentid,
        semester: semester,
        programmeyear: programyear,
      },
      { observe: "response" }
    );
  }
  deanapproveform2(
    semester: any,
    programyesar: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanapproveForm2",
      {
        semester: semester,
        programmeYear: programyesar,
        admission: admission,
      },
      { observe: "response" }
    );
  }
  registrarapproveform2(
    semester: any,
    programyesar: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarapproveForm2",
      {
        semester: semester,
        programmeYear: programyesar,
        admission: admission,
      },
      { observe: "response" }
    );
  }

  deandisapproveform2(
    semester: any,
    programyesar: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deanDisapproveForm2",
      {
        semester: semester,
        programmeYear: programyesar,
        admission: admission,
      },
      { observe: "response" }
    );
  }

  registrardisapproveform2(
    semester: any,
    programyesar: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm2",
      {
        semester: semester,
        programmeYear: programyesar,
        admission: admission,
      },
      { observe: "response" }
    );
  }


  checkapprovalforform2(
    admission: any,
    programyear: any,
    academicyear: any,
    program: any,
    semester: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkForm2Approval",
      {
        admission: admission,
        programmeYear: programyear,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degree

      },
      { observe: "response" }
    );
  }

  disapproveregistrarform1(
    semester: any,
    programyear: any,
    admission: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm1",
      {
        semester: semester,
        programmeYear: programyear,
        admission: admission
      },
      { observe: "response" }
    );
  }

  checkform1approval(
    admission: any,
    programyear: any,
    academicyear: any,
    program: any,
    semester: any,
    degrre: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkForm1Approval",
      {
        admission: admission,
        programmeYear: programyear,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degrre
      },
      { observe: "response" }
    );
  }


  applyforqualifyingexam(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/applyQualifyingExam",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }

  checkstudentsubmittedguideandme(
    academicyear: any,
    admission: any,
    program: any,
    degree: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkStudentSubmittedGuideAndMembers",
      {
        academicYear: academicyear,
        admission: admission,
        programmes: program,
        degree: degree,
        programmeYear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }

  savestudentmember(
    academicyear: any,
    college: any,
    active: any,
    admission: any,
    program: any,
    staffperson: any,
    degree: any,
    programyear: any,
    semester: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/saveStudentMembers",
      {
        academicYear: academicyear,
        colleges: college,
        exIsActive: active,
        admission: admission,
        programmes: program,
        staffpersonal: staffperson,
        degree: degree,
        programmeYear: programyear,
        semester: semester,
        user: user
      },
      { observe: "response" }
    );
  }

  registrardisapproveform6(
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/registrarDisapproveForm6",
      {
        admission: studentid
      },
      { observe: "response" }
    );
  }
  studentlistforqualifyingexam(
    programyera: any,
    academicyear: any,
    semestre: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/studentListForQualifyingExam",
      {
        programmeYear: programyera,
        academicYear: academicyear,
        semester: semestre
      },
      { observe: "response" }
    );
  }


  cjeckstudentappliedqualifyingexam(
    admisssion: any,
    programyear: any,
    academicyear: any,
    program: any,
    semester: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkStudentAppliedQualyfyingExam",
      {
        admission: admisssion,
        programmeYear: programyear,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degree
      },
      { observe: "response" }
    );
  }

  checkform3apprival(
    admisssion: any,
    programyear: any,
    academicyear: any,
    program: any,
    semester: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkForm3Approval",
      {
        admission: admisssion,
        programmeYear: programyear,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degree
      },
      { observe: "response" }
    );
  }

  checkform6apprival(
    admisssion: any,
    programyear: any,
    academicyear: any,
    program: any,
    semester: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkForm6Approval",
      {
        admission: admisssion,
        programmeYear: programyear,
        academicYear: academicyear,
        programmes: program,
        semester: semester,
        degree: degree
      },
      { observe: "response" }
    );
  }

  documentuploaded(
    studentid: any,
    documenttype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getUplodedDocument",
      {
        studentId: studentid,
        documentType: documenttype
      },
      { observe: "response" }
    );
  }
  generateprogressreport(
    college: any,
    semester: any,
    program: any,
    degree: any,
    programyear: any,
    academicyear: any,
    remark: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateSemesterReportPG",
      {
        colleges: college,
        semester: semester,
        programmes: program,
        degree: degree,
        programmeYear: programyear,
        academicYear: academicyear,
        exRemark: remark,
        user: user
      },
      { observe: "response" }
    );
  }

  generatecomponentmarks(
    academicyear: any,
    programyear: any,
    semester: any,
    course:any,
    degree: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentsGradePointForPg",
      {
        academicYear:academicyear,
        programmeYear:programyear,
        semster:semester,
        courses:course,
        degree:degree,
        user:user,
      },
      { observe: "response" }
    );
  }

  registercourseforAdmin(
    academicyear: any,
    programyear: any,
    semester: any,
    college: any,
    regtype: any,
    degree: any,
    program: any,
    studentid: any,
    user: any,
    courselist: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/addingCoursesByAdminWithResearch",
      {
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        colleges: college,
        registrationType: regtype,
        degree: degree,
        programmes: program,
        studentId: studentid,
        user: user,
        coursesList: courselist,
      },
      { observe: "response" }
    );
  }

  addingcourse(
    academicyear: any,
    programyear: any,
    semester: any,
    college: any,
    regtype: any,
    course: any,
    degree: any,
    program: any,
    studentid: any,
    user:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/addingCoursesByAdmin",
      {
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        colleges: college,
        registrationType: regtype,
        courses: course,
        degree: degree,
        programmes: program,
        studentId: studentid,
        user:user
      },
      { observe: "response" }
    );
  }


  dropcourseAdmin(
    studentid: any,
    course: any,
    programyear: any,
    semester: any,
    user: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/droppingCoursesByAdmin",
      {
        studentId: studentid,
        courses: course,
        programmeYear: programyear,
        semester: semester,
        user: user,
      },
      { observe: "response" }
    );
  }

  approvegrade(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    approve: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/approvePGStudent",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
        isApprove: approve
      },
      { observe: "response" }
    );
  }



  credithourforpg(

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/creditHours/pg/get",
      {

      },
      { observe: "response" }
    );
  }

  dashboardconfig(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
    startdate: any,
    enddate: any,
    user: any,
    course: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/saveDashboardCount",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        programmeyear: programyear,
        academicyear: academicyear,
        startDate: startdate,
        endDate: enddate,
        user: user,
        courses: course
      },
      { observe: "response" }
    );
  }

  dashboardconfigupdate(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
    startdate: any,
    enddate: any,
    user: any,
    course: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/dashboard/updateDashboardCount",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        programmeyear: programyear,
        academicyear: academicyear,
        startDate: startdate,
        endDate: enddate,
        user: user,
        courses: course
      },
      { observe: "response" }
    );
  }

  validatecourse(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    programyear: any,
    semester: any,
    staff: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/validateGradeSubmission",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmeYear: programyear,
        semester: semester,
        staff: staff,
      },
      { observe: "response" }
    );
  }

  getcourses(
    college: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    stafftype: any,
    staff: any,
    regtype: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCoursesOnStaffid",
      {
        colleges: college,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeYear: programyear,
        semster: semester,
        staffType: stafftype,
        staff: staff,
        registrationType: regtype,
        degree: degree,
      },
      { observe: "response" }
    );
  }


  onlinepayByAdmin(
    academicyear: any,
    programyear: any,
    college: any,
    progrm: any,
    semester: any,
    degree: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/onlinePayInitByAdmin",
      {
        academicYear: academicyear,
        programmeYear: programyear,
        colleges: college,
        programmes: progrm,
        semester: semester,
        degree: degree,
        exStudentId: studentid
      },
      { observe: "response" }
    );
  }

  section(
    type: any,
    uname: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/section/" + type,
      {
        exName: uname,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  credithours(
    type: any,
    credithourpractical: any,
    credithourtheory: any,
    credithourtitle: any,
    externalmakrs: any,
    externalpassingmarks,
    active: any,
    nooftopic: any,
    passinggradepoints: any,
    totalmarks: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/creditHours/" + type,
      {
        exCreditHourPractical: credithourpractical,
        exCreditHourTheory: credithourtheory,
        exCreditHourTitle: credithourtitle,
        exExternalMarks: externalmakrs,
        exExternalPassingMarks: externalpassingmarks,
        exIsActive: active,
        exNoOfTopics: nooftopic,
        exPassingGradePoints: passinggradepoints,
        exTotalMarks: totalmarks,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  departments(
    type: any,
    exname: any,
    college: any,
    degree: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/department/" + type,
      {
        exName: exname,
        colleges: college,
        degree: degree,
        exDescription: desc,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }


  componentmaster(
    type: any,
    componentmaster: any,
    degree: any,
    user: any,
    id: any,
    maxmarks:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/"+type+"ComponentMaster" ,
      {
        exComponentName:componentmaster,
        degree:degree,
        user: user,
        id: id,
        maxMarks:maxmarks
      },
      { observe: "response" }
    );
  }
  
  componentmasterbasedcoursetype(
   
    componenttype: any,
  
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getComponentOnCoursesType" ,
      {
        coursetype:componenttype,
     
      },
      { observe: "response" }
    );
  }

  componentcoursetype(
    type: any,
    couretypename: any,
    degree: any,
    coursemaster: any,
    user:any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/"+type+"EduCoursesType" ,
      {
        exEduCourseTypeName:couretypename,
        degree:degree,
        exEduCoursesTypes:coursemaster,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }


  departmentbaseddegree(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/departmentOnDegree",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  stafftype(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/staffType/get",
      {},
      { observe: "response" }
    );
  }

  getstaff(
    stafftype: any,
    college: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getStaffList",
      {
        staff: stafftype,
        clg: college,
        degre: degree,
      },
      { observe: "response" }
    );
  }

 

  submitmarksadmin(
    academicyear: any,
    course: any,
    degree: any,
    studentid:any,
    program:any,
    college:any,
    user:any,
    programyear:any,
    semester:any,
    details:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateComponentMarksByAdmin",
      {
        academicYear: academicyear,
        course: course,
        degree: degree,
        studentId:studentid,
        programme: program,
        college:college,
        user:user,
        programYear:programyear,
        semester:semester,
        details:details
      },
      { observe: "response" }
    );
  }

  getstafflist(
    academicyear: any,
    program: any,
    course: any,
    stafftyep: any,
    degree: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStaffList",
      {
        academicYear: academicyear,
        programmes: program,
        courses: course,
        staffType: stafftyep,
        degree: degree,
        colleges: college
      },
      { observe: "response" }
    );
  }

  designationbasedondepartment(
    department: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/designationBasedonOnDepartment",
      {
        dept: department,
      },
      { observe: "response" }
    );
  }

  designations(
    type: any,
    exname: any,
    college: any,
    dept: any,
    desc: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/designation/" + type,
      {
        exName: exname,
        colleges: college,
        department: dept,
        exDescription: desc,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //dicipline on program API
  disciplineOnProgram(program: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/disciplineOnProgramme",
      {
        exName: program,
      },
      { observe: "response" }
    );
  }

  //dicipline on program API
  convocationstudentdetails(studentid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/convocation/getStudentDetails",
      {
        studentId: studentid,
      },
      { observe: "response" }
    );
  }

  course(
    type: any,
    academics: any,
    names: any,
    program: any,
    discipline: any,
    description: any,
    active: any,
    coursecode: any,
    programyear: any,
    semester: any,
    criteria: any,
    credithours: any,
    iselective: any,
    ispractical: any,
    degree: any,
    user: any,
    id: any,
    coursetype:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/courses/" + type,
      {
        academicYear: academics,
        exName: names,
        programmes: program,
        discipline: discipline,
        exDescription: description,
        exIsActive: active,
        exCoursesCode: coursecode,
        programmeyear: programyear,
        semester: semester,
        exCriteria: criteria,
        creditHours: credithours,
        isElective: iselective,
        exIsPractical: ispractical,
        degree: degree,
        user: user,
        id: id,
        eduCourseType:coursetype
      },
      { observe: "response" }
    );
  }

  course1(
    type: any,
    names: any,
    degree: any,
    academicyear: any,
    description: any,
    program: any,
    discipline: any,
    id: any,
    active: any,
    coursecode: any,
    criteria: any,
    credithours: any,
    iselective: any,
    ispractical: any,
    umbrello: any,
    user: any,
    scheme: any,
    coursetype:any,
    isnc:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "CoursesPG",
      {
        exName: names,
        degree: degree,
        academicYear: academicyear,
        exDescription: description,
        programmes: program,
        discipline: discipline,
        id: id,
        exIsActive: active,
        exCoursesCode: coursecode,
        exCriteria: criteria,
        creditHours: credithours,
        isElective: iselective,
        exIsPractical: ispractical,
        exIsUmbrella: umbrello,
        user: user,
        exScheme: scheme,
        eduCourseType:coursetype,
        exIsNc:isnc
      },
      { observe: "response" }
    );
  }

  getCourse(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/courses/get",
      {},
      { observe: "response" }
    );
  }

  getScale(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getScales",
      {},
      { observe: "response" }
    );
  }

  getAboutFeedback(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getAboutFeedBacks",
      {},
      { observe: "response" }
    );
  }

  //course on DPA API
  getCourseonDPA(
    first: any,
    second: any,
    third: any,
    fourth: any,
    program: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/courseForGroupOnAcademicYear",
      {
        firstYear:first,
    secondYear:second,
    thirdYear:third,
    fourthYear:fourth,
     programmes:program
      },
      { observe: "response" }
    );
  }

  coursegroup(
    type: any,
    exname: any,
    degree: any,
    program: any,
    active: any,
    course: any,
    academicyear: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/courseGroup/" + type,
      {
        exName: exname,
        degree: degree,
        programmes: program,
        exIsActive: active,
        courses: course,
        academicYear: academicyear,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //upload data api
  uploadProgram(programfile: any, user: any) {
    const formData = new FormData();
    formData.append("exProgrammeFile", programfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/master/uploadProgrammes",
      formData
    );
  }

  //upload data api
  uploadInternalmarks(marksfile: any, academicyear: any, course: any, degree: any, program: any, college: any, semester: any, programyear: any, staff: any) {
    const formData = new FormData();
    formData.append("exIaFile", marksfile);
    formData.append("academicYear", academicyear);
    formData.append("courses", course);
    formData.append("degree", degree);
    formData.append("programmes", program);
    formData.append("colleges", college);
    formData.append("semster", semester);
    formData.append("programmeyear", programyear);
    formData.append("staff", staff);
    return this.http.post(
      environment.endpoint + "/academic/uploadInternalMarks",
      formData
    );
  }

    //upload data api
    uploadInternalmarksexacle(file: any,user:any,semester: any, programyear: any,program: any, degree: any, academicyear: any, staff: any,componentid:any,college: any, course: any) {
      const formData = new FormData();
      formData.append("compnentMarksfile", file);
      formData.append("user", user);
      formData.append("semester", semester);
      formData.append("programYear", programyear);
      formData.append("programme", program);
      formData.append("degree", degree);
      formData.append("academicYear", academicyear);
      formData.append("staff", staff);
      formData.append("componentid", componentid);
      formData.append("college", college);
      formData.append("course", course);
      return this.http.post(
        environment.endpoint + "/academic/uploadComponentMarks",
        formData
      );
    }

  //upload data api
  uploadStudentProfile(studentfile: any, user: any) {
    const formData = new FormData();
    formData.append("StudentProfilefile", studentfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadStudentProfile",
      formData
    );
  }

  uploadExamination(studentfile: any, user: any) {
    const formData = new FormData();
    formData.append("examinationFile", studentfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadExaminationDetails",
      formData
    );
  }

  //upload data api
  uploadStaffProfile(stafffile: any, user: any) {
    const formData = new FormData();
    formData.append("staffProfilefile", stafffile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadStaffProfile",
      formData
    );
  }

  //upload offlinepayment
  uploadOfflinepayment(academicyear: any, degree: any, programyear: any, college: any, program: any, sem: any, studentid: any, feeid: any, totalamount: any, paymentmethod: any, ddno: any, user: any, payreceipt: any) {
    const formData = new FormData();
    formData.append("academicYear", academicyear);
    formData.append("degree", degree);
    formData.append("programmeYear", programyear);
    formData.append("colleges", college);
    formData.append("programmes", program);
    formData.append("semester", sem);
    formData.append("exStudentId", studentid);
    formData.append("feeId", feeid);
    formData.append("exTotalAmount", totalamount);
    formData.append("paymentMethod", paymentmethod);
    formData.append("checkDdNumber", ddno);
    formData.append("user", user);
    formData.append("payReceipt", payreceipt);
    return this.http.post(
      environment.endpoint + "/postexam/counterPayment",
      formData
    );
  }

  uploadOfflinepaymentnri(academicyear: any, degree: any, programyear: any, college: any, program: any, sem: any, studentid: any, totalamount: any, paymentmethod: any, user: any, payreceipt: any) {
    const formData = new FormData();
    formData.append("academicYear", academicyear);
    formData.append("degree", degree);
    formData.append("programmeYear", programyear);
    formData.append("colleges", college);
    formData.append("programmes", program);
    formData.append("semester", sem);
    formData.append("exStudentId", studentid);
    formData.append("exTotalAmount", totalamount);
    formData.append("paymentMethod", paymentmethod);
    formData.append("user", user);
    formData.append("payReceipt", payreceipt);
   
    return this.http.post(
      environment.endpoint + "/postexam/offlineNRIPayment",
      formData
    );
  }

  //upload utility api
  uploadUtility(stafffile: any, user: any) {
    const formData = new FormData();
    formData.append("eligibleStudentsDetails", stafffile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/convocation/uploadEligibleStudentsDetails",
      formData
    );
  }

  //upload recounciliation api
  uploadRecouciliation(file: any) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      environment.endpoint + "/academic/uploadBilldeskReport",
      formData
    );
  }

  //upload data api
  uploadCourse(coursefile: any, user: any) {
    const formData = new FormData();
    formData.append("exCourseFile", coursefile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/master/uploadCourses",
      formData
    );
  }

  //upload data api
  uploadHostelConfiguration(hosteldetails: any, user: any) {
    const formData = new FormData();
    formData.append("hostelDetailsfile", hosteldetails);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadHostelConfigurationDetails",
      formData
    );
  }

  //upload data api
  uploadCoursemapping(coursefile: any, user: any) {
    const formData = new FormData();
    formData.append("teacherCourseMappingfile", coursefile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadTeacherCourseMapping",
      formData
    );
  }

  studentidsetting(
    type: any,
    college: any,
    degree: any,
    program: any,
    academicyear: any,
    startwith: any,
    exidvalue: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "StudentIdFormat",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        academicYear: academicyear,
        exStartWith: startwith,
        exIdValue: exidvalue,
        exIsActive: active,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  getCourseonp(exaname: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/coursesOnProgramme",
      {
        exName: exaname,
      },
      { observe: "response" }
    );
  }

  updatestudentsection(
    college: any,
    program: any,
    degree: any,
    studentidfrom: any,
    studentidto: any,
    section: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateStudentSection",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        studentIdFrom: studentidfrom,
        studentIdTo: studentidto,
        section: section,
        user: user,
      },
      { observe: "response" }
    );
  }

  changepassword(
    username: any,
    oldpassword: any,
    newpassword: any,
    logintype: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/changePassword",
      {
        username: username,
        oldpassword: oldpassword,
        newpassword: newpassword,
        loginType: logintype,
      },
      { observe: "response" }
    );
  }


  //upload data api
  uploadBulkregistration(studentreg: any, user: any) {
    const formData = new FormData();
    formData.append("studentRegistrationfile", studentreg);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadStudentRegistration",
      formData
    );
  }

  //upload data api
  uploadStudentPhoto(image: any, id: any, usertype: any, user: any) {
    const formData = new FormData();
    formData.append("image_url", image);
    formData.append("id", id);
    formData.append("userType", usertype);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadImage",
      formData
    );
  }

  //upload data api for staff
  uploadStaffPhoto(image: any, id: any, usertype: any, user: any) {
    const formData = new FormData();
    formData.append("image_url", image);
    formData.append("id", id);
    formData.append("userType", usertype);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/academic/uploadImage",
      formData
    );
  }

  //upload data api for staff
  uploadpreloginpagegallery(isimge:any,position: any, images: any, video: any) {
    const formData = new FormData();
    formData.append("isImage",isimge);
    formData.append("position", position);
    formData.append("images", images);
    formData.append("video", video);
    return this.http.post(
      environment.endpoint + "/masters/saveDashboardGallery",
      formData
    );
  }

  updatepreloginpagegallery(isimge:any,position: any, images: any, video: any,id:any) {
    const formData = new FormData();
    formData.append("isImage",isimge);
    formData.append("position", position);
    formData.append("images", images);
    formData.append("video", video);
    formData.append("id", id);
    return this.http.post(
      environment.endpoint + "/masters/updateDashboardGalleryImage",
      formData
    );
  }


  //upload data api for QP
  uploadQP(
    academic: any,
    degree: any,
    exam: any,
    course: any,
    qpfile: any,
    coursecode: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("academicYear", academic);
    formData.append("degree", degree);
    formData.append("examAnnouncement", exam);
    formData.append("courses", course);
    formData.append("exquestionpaperFile", qpfile);
    formData.append("courseCodeNo", coursecode);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/excel/uploadQuestionPaper",
      formData
    );
  }

  //upload OMR
  uploadOMR(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    regtype: any,
    omrfile: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("colleges", college);
    formData.append("semster", semester);
    formData.append("degree", degree);
    formData.append("programmes", program);
    formData.append("courses", course);
    formData.append("examannouncement", exam);
    formData.append("programmeYear", programyear);
    formData.append("registrationType", regtype);
    formData.append("exOmrFile", omrfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/uploadOmrMarks",
      formData
    );
  }

  //upload external marks
  externalMarksupload(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    regtype: any,
    omrfile: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("colleges", college);
    formData.append("semster", semester);
    formData.append("degree", degree);
    formData.append("programmes", program);
    formData.append("courses", course);
    formData.append("examannouncement", exam);
    formData.append("programmeYear", programyear);
    formData.append("registrationType", regtype);
    formData.append("exOmrFile", omrfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/uploadExternalMarks",
      formData
    );
  }

  //upload external marks
  uploadBookletzip(
    college: any,
    academicyear: any,
    program: any,
    course: any,
    exam: any,
    regtype: any,
    booklet: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("colleges", college);
    formData.append("academicYear", academicyear);
    formData.append("programmes", program);
    formData.append("course", course);
    formData.append("examAnnouncement", exam);
    formData.append("registrationType", regtype);
    formData.append("booklet", booklet);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/bookletUpload",
      formData
    );
  }

  //upload qp andkeys
  uploadqpandkeys(
    degree: any,
    academicyear: any,
    program: any,
    course: any,
    exam: any,
    qpfile: any,
    ansfile: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("degree", degree);
    formData.append("academicYear", academicyear);
    formData.append("programmes", program);
    formData.append("courses", course);
    formData.append("examAnnouncement", exam);
    formData.append("exQpFile", qpfile);
    formData.append("exAnswerKeyFile", ansfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/uploadQPKeyAnswer",
      formData
    );
  }
  uploadqpandkeys1(
    degree: any,
    academicyear: any,
    program: any,
    course: any,
    exam: any,
    qpfile: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("degree", degree);
    formData.append("academicYear", academicyear);
    formData.append("programmes", program);
    formData.append("courses", course);
    formData.append("examAnnouncement", exam);
    formData.append("exQpFile", qpfile);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/uploadQPKeyAnswer",
      formData
    );
  }


  //upload external marks
  submitEvalatorDetails(
    firstname: any,
    mobile: any,
    empid: any,
    evaluationtime: any,
    evaluationfrom: any,
    evaluationto: any,
    evaluationvenue: any,
    academicyear: any,
    exam: any,
    college: any,
    evaluationtype: any,
    course: any,
    staffid: any,
    staff: any,
    user: any
  ) {
    const formData = new FormData();
    formData.append("exFirstName", firstname);
    formData.append("exMobile", mobile);
    formData.append("exEmployeeId", empid);
    formData.append("exEvaluationTime", evaluationtime);
    formData.append("exEvaluationFrom", evaluationfrom);
    formData.append("exEvaluationTo", evaluationto);
    formData.append("exEvaluationVenue", evaluationvenue);
    formData.append("academicYear", academicyear);
    formData.append("examAnnouncement", exam);
    formData.append("colleges", college);
    formData.append("evaluationType", evaluationtype);
    formData.append("courses", course);
    formData.append("staffId", staffid);
    formData.append("staff", staff);
    formData.append("user", user);
    return this.http.post(
      environment.endpoint + "/postexam/saveEvaluatorSelection",
      formData
    );
  }

  //student format year API
  createstudentIDFormat(
    college: any,
    degree: any,
    program: any,
    academicyear: any,
    startwith: any,
    idvalue: any,
    active: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createStudentIdFormat",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        academicYear: academicyear,
        exStartWith: startwith,
        exIdValue: idvalue,
        exIsActive: active,
        user: user,
      },
      { observe: "response" }
    );
  }

  //create student format API
  getstudentIDFormat(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentIdFormats",
      {},
      { observe: "response" }
    );
  }

  //release booklet
  relesebooklet(studentid: any, user: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/releaseStudentProfile",
      {
        studentid: studentid,
        user: user,
      },
      { observe: "response" }
    );
  }

  //release booklet
  getStudentProfileAdmin(collegeid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentProfileOnCollege",
      {
        colleges: collegeid,
      },
      { observe: "response" }
    );
  }

  getStaffProfileAdmin(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getAllStaffs",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  deletestaffproile(ids: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/deleteStaffProfile",
      {
        id: ids,
      },
      { observe: "response" }
    );
  }


  getExaminationDetails(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getAllExamAnnouncements",
      {},
      { observe: "response" }
    );
  }

  regitsrationtypeonexam(exam: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/registrationTypeOnExam",
      {
        examAnnouncement: exam,
      },
      { observe: "response" }
    );
  }

  registrationtypeoner(exam: any, regtype: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getEvaluationAndRegistration",
      {
        examAnnouncement: exam,
        registrationType: regtype
      },
      { observe: "response" }
    );
  }

  getregistrationstatus(academic: any, college: any, degree: any, exam: any, regtype: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/validateSemesterConfiguration",
      {
        academicYear: academic,
        colleges: college,
        degree: degree,
        examAnnouncement: exam,
        registrationType: regtype
      },
      { observe: "response" }
    );
  }

  getregistrationstatuspg(academic: any, college: any, degree: any, program: any, programyear: any, semester: any,regtype:any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/validatePGConfiguration",
      {
        academicYear: academic,
        colleges: college,
        degree: degree,
        programme: program,
        programmeYear: programyear,
        semester: semester,
        registrationType:regtype
      },
      { observe: "response" }
    );
  }

  getElectivecourse(electivecourse: any,): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/courseOnGroupId",
      {
        electiveGroup: electivecourse
      },
      { observe: "response" }
    );
  }

  getfeenameandDACno(college: any, degree: any, program: any, feetyep: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getDacnumberAndFeename",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        feeType: feetyep,
      },
      { observe: "response" }
    );
  }

  getfeenameandacbasedOnCD(college: any, degree: any,): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getFeeCategoryOnDegree",
      {
        colleges: college,
        degree: degree,
      },
      { observe: "response" }
    );
  }

  registrationtypeget(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getRegistrationType",
      {},
      { observe: "response" }
    );
  }

  coursesonPAER(
    academicyear: any,
    program: any,
    registrationtype: any,
    exam: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/courseOnExam",
      {
        academicYear: academicyear,
        programmes: program,
        registrationType: registrationtype,
        examAnnouncement: exam,
      },
      { observe: "response" }
    );
  }

  coursesoncoursetypeandcompoenet(
    academicyear: any,
    program: any,
    degree:any,
    componentcourse: any,
    exam:any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCourseOnComponentCourseType",
      {
        academicYear:academicyear,
        programmes:program,
        degree:degree,
        componentCourse:componentcourse,
        examAnnouncement:exam,

      },
      { observe: "response" }
    );
  }

  coursesoncoursetypeandcompoenetpg(
    college: any,
    degree: any,
    academicyear:any,
    programyear:any,
    program: any,
    semester: any,
    component:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getPgCoursesOnComponentCourseType",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        programmes: program,
        semester: semester,
        componentCourse:component
      },
      { observe: "response" }
    );
  }

  getregisttredCourse(
    college: any,
    degree: any,
    academicyear: any,
    programyear: any,
    program: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getRegisteredCoursesForPg",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        programmes: program,
        semester: semester
      },
      { observe: "response" }
    );
  }

  coursesonP(
    program: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/coursesOnProgrammeDetails",
      {
        programmeId: program,

      },
      { observe: "response" }
    );
  }


  submitupdatebsent(barcode: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateStudentAbsent",
      {
        barcode: barcode,
      },
      { observe: "response" }
    );
  }

  uploadDirect(
    college: any,
    academicyear: any,
    program: any,
    regtype: any,
    exam: any,
    course: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/createBookletFolder",
      {
        colleges: college,
        academicYear: academicyear,
        programmes: program,
        registrationType: regtype,
        examAnnouncement: exam,
        course: course,
        user: user,
      },
      { observe: "response" }
    );
  }


  getinternalstudentdata(
    academicyear: any,
    course: any,
    program: any,
    degree: any,
    college: any,
    section: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentForInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        degree: degree,
        colleges: college,
        section: section
      },
      { observe: "response" }
    );
  }


  getstaffdetailsforallocation(
    academicyear: any,
    evaluationtype: any,
    college: any,
    exam: any,
    course: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getevaluatorlist",
      {
        academicYear: academicyear,
        evaluationType: evaluationtype,
        colleges: college,
        examAnnouncement: exam,
        courses: course,
      },
      { observe: "response" }
    );
  }

  submitReallocation(
    academicyear: any,
    program: any,
    college: any,
    oldstaffid: any,
    newstaffid: any,
    regtype: any,
    exam: any,
    course: any,
    barcodelist: any,
    degree: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/reAllocateBooklet",
      {
        academicYear: academicyear,
        programmes: program,
        colleges: college,
        oldstaffid: oldstaffid,
        newstaffid: newstaffid,
        registrationType: regtype,
        examAnnouncement: exam,
        course: course,
        barcodelist: barcodelist,
        degree: degree,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitReallocationreevaluation(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    college: any,
    program: any,
    regtype: any,
    oldstaffid: any,
    newstaffid: any,
    barcodelist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/reAllocationBooklet",
      {
        degree:degree,
        academicYear:academicyear,
        examAnnouncement:exam,
        course:course,
        colleges:college,
        programmes:program,
        registrationType:regtype,
        oldstaffid:oldstaffid,
        newstaffid:newstaffid,
        barcodelist:barcodelist,
        user:user
      },
      { observe: "response" }
    );
  }

  getbarcodedetailsonEvaluator(
    academicyear: any,
    program: any,
    programyear: any,
    semester: any,
    registrationtype: any,
    exam: any,
    course: any,
    degree: any,
    staff: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getBarcodeDetailsOnEvaluator",
      {
        academicYear: academicyear,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
        registrationType: registrationtype,
        examAnnouncement: exam,
        course: course,
        degree: degree,
        staffid: staff,
      },
      { observe: "response" }
    );
  }

  getbarcodedetailsonreEvaluator(
    degree: any,
    program: any,
    course: any,
    exam: any,
    college:any,
    programyear: any,
    academicyear: any,
    semester: any,
    staff: any,  
    registrationtype: any,
     
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getBarcodeDetailsOnreEvaluator",
      {
        degree: degree,
        programmes: program,
        course: course,
        examAnnouncement: exam,
        colleges: college,
        programmeYear:programyear,
        academicYear:academicyear,
        semester:semester,
        staffid:staff,
        registrationType:registrationtype
      },
      { observe: "response" }
    );
  }

  submitBookletAllocation(
    academicyear: any,
    program: any,
    college: any,
    staffid: any,
    regtype: any,
    exam: any,
    course: any,
    extscore: any,
    limitsperday: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/bookletAllocation",
      {
        academicYear: academicyear,
        programmes: program,
        colleges: college,
        staffId: staffid,
        registrationType: regtype,
        examAnnouncement: exam,
        course: course,
        allocatedbooklet: extscore,
        limitperday: limitsperday,
        user: user,
      },
      { observe: "response" }
    );
  }


  submitBookletAllocationrev(
    college: any,
    degree:any,
    academicyear: any,
    program: any,
    exam: any,
    regtype: any,
    course: any,
    staffid: any,
  allocatedbooklet:any,
    limitsperday: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/bookletReAllocation",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmes: program,
        examAnnouncement: exam,
        registrationType: regtype,
        course: course,
        staffId: staffid,
        allocatedbooklet: allocatedbooklet,
        limitperday: limitsperday,
        user: user
      },
      { observe: "response" }
    );
  }


  getstudentNumber(
    academicyear: any,
    program: any,
    regtype: any,
    exam: any,
    course: any,
    degree: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentCount",
      {
        academicYear: academicyear,
        programmes: program,
        registrationType: regtype,
        examAnnouncement: exam,
        course: course,
        degree: degree,
        colleges: college,
      },
      { observe: "response" }
    );
  }

  getstudentNumberreeval(
    academicyear: any,
    exam: any,
    course: any,
    degree: any,
    program: any,
    regtype: any,
  
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentReEvaluationCount",
      {
        academicYear:academicyear,
        examAnnouncement:exam,
        course:course,
        degree : degree,
        programmes:program,
        registrationType:regtype
      },
      { observe: "response" }
    );
  }

  
  qpjumbling(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    Version: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/jumble",
      {
        degree: degree,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        version: Version,
      },
      { observe: "response" }
    );
  }

  submitElectiveCourse(
    college: any,
    program: any,
    course: any,
    exam: any,
    academicyear: any,
    groupname: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/electiveGroupMapping",
      {
        colleges: college,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        academicYear: academicyear,
        exGroupName: groupname,
        user: user,
      },
      { observe: "response" }
    );
  }
  coursesonPAPS(
    academicyear: any,
    program: any,
    programyear: any,
    sem: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCourseList",
      {
        academicYear: academicyear,
        programmes: program,
        programmeYear: programyear,
        semster: sem,
      },
      { observe: "response" }
    );
  }

  courseforpg(
    college: any,
    degree: any,
    academicyear: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    stafftype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCoursesOnStaffidForPG",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmes: program,
        programmeYear: programyear,
        semster: semester,
        staff: staff,
        staffType: stafftype,
      },
      { observe: "response" }
    );
  }

  detailsforIAMarks(
    academicyear: any,
    course: any,
    program: any,
    dgeree: any,
    college: any,
    section: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentForInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        degree: dgeree,
        colleges: college,
        section: section,
      },
      { observe: "response" }
    );
  }

  detailsforfeetrans(
    college: any,
    degree: any,
    program: any,
    semester: any,
    orderid: any,
    studentid: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    paymentstatus: any,
    tnxrefno: any,
    category: any,
    feetype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/report/transactionReports",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        orderId: orderid,
        studentId: studentid,
        programmeyear: programyear,
        startDate: startdate,
        endDate: enddate,
        academicyear: academicyear,
        paymentStatus: paymentstatus,
        txnReferenceNo: tnxrefno,
        category: category,
        feeType: feetype
      },
      { observe: "response" }
    );
  }

  detailsforfeetranshistory(
    startdate: any,
    enddate: any,
    orderid: any,
    studentid: any,
    tnsxrefno: any,
    paymentstatus: any,
    academicyear: any,
    college: any,
    program: any,
    programyear: any,
    semsetr: any,
    category: any,
    feetype: any,
    degree: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/history/transactionReportHistory",
      {
        startDate: startdate,
        endDate: enddate,
        orderId: orderid,
        studentId: studentid,
        txnReferenceNo: tnsxrefno,
        paymentStatus: paymentstatus,
        academicyear: academicyear,
        colleges: college,
        programmes: program,
        programmeyear: programyear,
        semester: semsetr,
        category: category,
        feeType: feetype,
        degree: degree
      },
      { observe: "response" }
    );
  }


  detailsforconsolidated(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    paymentstatus: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/utility/checTransactionConsolidated",
      {
        college: college,
        degree: degree,
        programmes: program,
        semester: semester,
        programmeyear: programyear,
        exStartDate: startdate,
        exEndDate: enddate,
        academicyear: academicyear,
        paymentStatus: paymentstatus
      },
      { observe: "response" }
    );
  }
  detailsforconsolidatedhistory(
    startdate: any,
    enddate: any,
    paymentstatus: any,
    academicyear: any,
    college: any,
    program: any,
    programyear: any,
    semester: any,
    degree: any,


  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/history/checkHistoryTransactionConsolidated",
      {
        exStartDate: startdate,
        exEndDate: enddate,
        paymentStatus: paymentstatus,
        academicyear: academicyear,
        college: college,
        programmes: program,
        programmeyear: programyear,
        semester: semester,
        degree: degree

      },
      { observe: "response" }
    );
  }

  gradestatuspg(
    college: any,
    degree: any,
    academicyear: any,
    studentid: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getRegisteredCoursesGradesOfPgStudent",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        studentId: studentid,
        programmeYear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }

  getDataforAddordrop(
    degree: any,
    program: any,
    studentid: any,
    programyear: any,
    semester: any,
    scheme: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getCourseForAdmin",
      {
        degree: degree,
        programmes: program,
        studentId: studentid,
        programmeYear: programyear,
        semester: semester,
        scheme: scheme,
      },
      { observe: "response" }
    );
  }

  detailsforupdategradepoints(
    college: any,
    degree: any,
    academicyear: any,
    program: any,
    programyear: any,
    semester: any,
    course: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentForGrade",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmes: program,
        programmeYear: programyear,
        semster: semester,
        courses: course,
      },
      { observe: "response" }
    );
  }

  gradedetails(
    academicyear: any,
    course: any,
    program: any,
    degree: any,
    college: any,
    programyear: any,
    semester: any,
    regtype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentCredit",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        degree: degree,
        colleges: college,
        programmeYear: programyear,
        semster: semester,
        registrationType: regtype
      },
      { observe: "response" }
    );
  }

  getgreadesdata(
    college: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    course: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentForGrade",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        semster: semester,
        courses: course
      },
      { observe: "response" }
    );
  }


  getPhotocopyDetails(
    academicyear: any,
    course: any,
    program: any,
    dgeree: any,
    college: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getPhotocopyDetails",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        degree: dgeree,
        colleges: college,
        programmeYear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }

  detailsExternalMarks(
    degree: any,
    program: any,
    course: any,
    exam: any,
    college: any,
    programyear: any,
    semester: any,
    regtype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getMarks",
      {
        degree: degree,
        programmes: program,
        courses: course,
        examannouncement: exam,
        colleges: college,
        programmeYear: programyear,
        semster: semester,
        registrationType: regtype,
      },
      { observe: "response" }
    );
  }

  generatereevaluationlist(
    exam: any,
    academicyear: any,
   
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateReEvaluationList",
      {
        examannouncement: exam,
        academicYear: academicyear
      },
      { observe: "response" }
    );
  }

  getdataforCourseregistration(
    academicyear: any,
    program: any,
    programyear: any,
    semester: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getListOfCourses",
      {
        academicYear: academicyear,
        programmes: program,
        programmeyear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }

  getdataforbarcoddetails(
    barcode: any,
   
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getStudentDetailsOnBarcode",
      {
        barcode: barcode,
     
      },
      { observe: "response" }
    );
  }

  getfacultyDetails(
    college: any,
    degree: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/facultyOnCollegesAndDegree",
      {
        college: college,
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getCoursesforAdmin(
    degree: any,
    program: any,
    studentid: any,
    programyear: any,
    semester: any,
    academicyear: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getUGCourseForAdmin",
      {
        degree: degree,
        programmes: program,
        studentId: studentid,
        programmeYear: programyear,
        semester: semester,
        academicYear: academicyear
      },
      { observe: "response" }
    );
  }

  hostelAllocationDetails(
    college: any,
    academicyear: any,
    degree: any,
    program: any,
    programyear: any,
    semester: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentListForHostel",
      {
        colleges: college,
        academicyear: academicyear,
        degree: degree,
        programmes: program,
        programmeyear: programyear,
        semester: semester
      },
      { observe: "response" }
    );
  }

  gettabledataforinternalmarks(
    academicyear: any,
    course: any,
    program: any,
    degree: any,
    college: any,
    programyear: any,
    semester: any,
    section: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/studentForInternalMarks",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        degree: degree,
        colleges: college,
        programmeyear: programyear,
        semster: semester,
        section: section,
      },
      { observe: "response" }
    );
  }

  getBookletStatusDetails(
    exam: any,
    academic: any,
    program: any,
    regtype: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getBarcodeDigitalStatusInAdmin",
      {
        examAnnouncement: exam,
        academicYear: academic,
        programmes: program,
        registrationType: regtype,
        colleges: college,
      },
      { observe: "response" }
    );
  }

  getBookletStatusDetailsrev(
    exam: any,
    academic: any,
    program: any,
    regtype: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getBarcodeDigitalStatusInAdminforReEvaluation",
      {
        examAnnouncement: exam,
    academicYear: academic,
    programmes: program,
    registrationType: regtype,
    colleges: college
      },
      { observe: "response" }
    );
  }

  appversioning(
    type: any,
    apptype: any,
    version: any,
    desc: any,
    duedate: any,
    active: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/appVersion/" + type,
      {
        exAppType: apptype,
        exVersion: version,
        exDescription: desc,
        exDueDate: duedate,
        exIsActive: active,
        user: user,
        id: id
      },
      { observe: "response" }
    );
  }

  directpayment(
    type: any,
    degree: any,
    semester: any,
    programyear: any,
    academicyear: any,
    startdate: any,
    enddate: any,
    registrationtyep: any,
    name: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/directPaymet/" + type,
      {
        degree: degree,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
        exRegistrationStartDate: startdate,
        exRegistrationEndDate: enddate,
        registrationType: registrationtyep,
        exName: name
      },
      { observe: "response" }
    );
  }

  gettableforevaluatorselection(
    degree: any,
    college: any,
    discipline: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/loadStaff",
      {
        degree: degree,
        colleges: college,
        discipline: discipline,
      },
      { observe: "response" }
    );
  }

  //module loading API
  moduleLoading(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + '/masters/getPrivilegesModules',
      {},
      { observe: 'response' }
    );
  }

  //get dropdown value for module feature mapping
  getmoduleFeatureMapping(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + '/masters/getPrivilegesFeatures',
      {},
      { observe: 'response' }
    );
  }
  submitModulemapping(
    llabel: any,
    featurename: any,
    privilagesmodule: any,
    active: any,
    title: any,
    link: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/savePrivilegesFeatures",
      {
        exLabel: llabel,
        exFeaturename: featurename,
        privilegesModules: privilagesmodule,
        exIsActive: active,
        exTitle: title,
        link: link
      },
      { observe: "response" }
    );
  }

  savePrevilagedData(role: any, details: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/saveallPrivileges",
      {
        role: role,
        details: details,
      },
      { observe: "response" }
    );
  }


  //role API
  rolemaster(
    type: any,
    uname: any,
    exIsActive: any,
    user: any,
    navigatingurl: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + '/masters/' + type + 'Role',
      {
        exRoleName: uname,
        exIsActive: exIsActive,
        user: user,
        exNavigatingURL: navigatingurl
      },
      { observe: 'response' }
    );
  }


  getAllDetail(uid: any, role: any): Observable<HttpResponse<any>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getprivilegesOnRoleAndModule",
      {
        id: uid,
        role: role,
      },

      { observe: "response" }

    );
  }


  submitExternalMarks(
    degree: any,
    programme: any,
    course: any,
    exam: any,
    college: any,
    programyear: any,
    semester: any,
    regtype: any,
    studentid: any,
    omrtotalscore: any,
    externalscore: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/updateMarks",
      {
        degree: degree,
        programmes: programme,
        courses: course,
        examannouncement: exam,
        colleges: college,
        programmeYear: programyear,
        semster: semester,
        registrationType: regtype,
        exStudentId: studentid,
        exOmrTotalScore: omrtotalscore,
        exExternalScore: externalscore,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitCourseRegistration(
    academicyear: any,
    programyear: any,
    semester: any,
    college: any,
    exam: any,
    regtype: any,
    courselist: any,
    degree: any,
    program: any,
    studentid: any,
    user:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createSemesterRegistrationByCourseList",
      {
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtype,
        courselist: courselist,
        degree: degree,
        programmes: program,
        studentId: studentid,
        user:user
      },
      { observe: "response" }
    );
  }

  dropcoursesofUG(
    studentid: any,
    course: any,
    programyear: any,
    semester: any,
    user: any,
    program: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/droppingCoursesByAdminforUG",
      {
        studentId: studentid,
        courses: course,
        programmeYear: programyear,
        semester: semester,
        user: user,
        programmes: program
      },
      { observe: "response" }
    );
  }

  submitmanualquery(
    startdate: any,
    enddate: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/utility/checTransactionDaywise",
      {
        exStartDate: startdate,
        exEndDate: enddate,
      },
      { observe: "response" }
    );
  }

  getPassword(
    username: any,
    logintype: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getPassword",
      {
        username: username,
        loginType: logintype
      },
      { observe: "response" }
    );
  }

  getOTP(
    mobile: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/getOtpByAdmin",
      {
        mobile: mobile,

      },
      { observe: "response" }
    );
  }

  submitCourseRegistrationsupply(
    academicyear: any,
    programyear: any,
    semester: any,
    college: any,
    exam: any,
    regtype: any,
    courselist: any,
    degree: any,
    program: any,
    studentid: any,
    user:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/supplementaryRegistrationByAdmin",
      {
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtype,
        courses: courselist,
        degree: degree,
        programmes: program,
        studentId: studentid,
        user:user
      },
      { observe: "response" }
    );
  }

  getStudentDetails(
    college: any,
    program: any,
    dgeree: any,
    academic: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/studentForDeanApprove",
      {
        colleges: college,
        programmes: program,
        degree: dgeree,
        academicyear: academic,
        programmeyear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }

  examonProgramandacdemic(
    program: any,
    academic: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/examOnProgramAndAcademicYear",
      {
        programmes: program,
        academicYear: academic,
      },
      { observe: "response" }
    );
  }

  getcomponentmaster(
    academic: any,
    program: any,
  exam:any,
  regtype:any,
  degree:any,
  coursetype:any,
  college:any,
  course:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getInternalMarksComponent",
      {
        academicYear:academic,
    programme:program,
    examination:exam,
    registrationType:regtype,
    degree:degree,
    coursetype:coursetype,
    college:college,
    course:course
      },
      { observe: "response" }
    );
  }

  getcomponentmasterpg(
    academic: any,
   
  degree:any,
  coursetype:any,
  college:any,
  course:any,
  programyear:any,
  semester:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getPgInternalMarksComponent",
      {
        academicYear:academic,
    degree:degree,
    coursetype:coursetype,
    college:college,
    course:course,
    programYear:programyear,
     semester:semester
      },
      { observe: "response" }
    );
  }

  getexamdetails(
    exam: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getExamDetails",
      {
        examannouncement: exam,

      },
      { observe: "response" }
    );
  }

  validateaboutFeedBack(
    courses: any,
    programmeyear: any,
    semester: any,
    acdemicyear: any,
    student: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/validateAboutFeedback",
      {
        courses: courses,
        programmeYear: programmeyear,
        semster: semester,
        academicYear: acdemicyear,
        studentId: student
      },
      { observe: "response" }
    );
  }

  getstaffdetails(
    college: any,
    academicyear: any,
    course: any,
    program: any,
    stafftype: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStaffList",
      {
        colleges: college,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        staffType: stafftype,
        degree: degree

      },
      { observe: "response" }
    );
  }

  electivegroup(
    academic: any,
    college: any,
    degree: any,
    program: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/electiveGroupName",
      {
        academicYear: academic,
        colleges: college,
        degree: degree,
        programmes: program,
        programmeYear: programyear,
        semester: semester
      },
      { observe: "response" }
    );
  }

  checklibapproval(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    program: any,
    studentid: any,


  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkApprove",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        programmes: program,
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  checkaracapproval(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    degree: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkComptrollerAndRegistrarApprove",
      {
        academicYear:academic,
        programmeYear:programyear,
        semester:semester,
        programmes:program,
        studentId:studentid,
        degree:degree
        
      },
      { observe: "response" }
    );
  }

  changecoursethis(
    academic: any,
    college: any,
    degree: any,
    programyear: any,
    semester: any,
    program: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/validateSemesterConfigurationForCourseChange",
      {
        academicYear: academic,
        colleges: college,
        degree: degree,
        programmeYearId: programyear,
        semester: semester,
        programme: program,
      },
      { observe: "response" }
    );
  }

  deletecourse(
    academic: any,
    college: any,
    degree: any,
    programyear: any,
    semester: any,
    program: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/validateSemesterConfigurationForCourseChange",
      {
        academicYear: academic,
        colleges: college,
        degree: degree,
        programmeYearId: programyear,
        semester: semester,
        programme: program,
      },
      { observe: "response" }
    );
  }

  submitcourse(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    college: any,
    program: any,
    studentid: any,
    regtype: any,
    courselist: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/courseRegistrationStudent",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        colleges: college,
        programmes: program,
        studentId: studentid,
        registrationType: regtype,
        coursesList: courselist,
      },
      { observe: "response" }
    );
  }



  loadcoursesforPG(
    programid: any,
    studentid: any,
    scheme: any,



  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/coursesOnProgrammes",
      {
        programmeId: programid,
        StudentId: studentid,
        scheme: scheme,

      },
      { observe: "response" }
    );
  }

  dropCourse(

    studentid: any,
    course: any,



  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/deleteRegisteredCoursesOnStudent",
      {
        studentId: studentid,
        courses: course,

      },
      { observe: "response" }
    );
  }


  loadcourseregbystudent(
    academicyear: any,
    degree: any,
    program: any,
    programyear: any,
    semester: any,
    studentid: any,
    scheme: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getRegisteredCoursesByStudent",
      {
        academicYear: academicyear,
        degree: degree,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
        studentId: studentid,
        scheme: scheme
      },
      { observe: "response" }
    );
  }

  checkfeedback(
    academic: any,
    programyear: any,
    semester: any,
    studentid: any,
    degree: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkFBSubmitted",
      {
        academicYear: academic,
        programmeYear: programyear,
        semster: semester,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  semesterregistration(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    college: any,
    program: any,
    studentid: any,
    exam: any,
    regtyep: any,
    electivegroup: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createSemesterRegistration",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        colleges: college,
        programmes: program,
        studentId: studentid,
        examAnnouncement: exam,
        registrationType: regtyep,
        electiveGroup: electivegroup
      },
      { observe: "response" }
    );
  }

  supplementaryregistration(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    exam: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/supplementaryRegistration",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  reevaluationregistration(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    exam: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/reEvaluation",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }


  reevaluationregistrationpg(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/pgReEvaluation",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  pgotocopyregistration(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    exam: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/xeroxCopy",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  pgotocopyregistrationpg(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/pgXeroxCopy",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  reregistration(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    exam: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/xeroxCopy",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  sreevalregistration(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    college: any,
    exam: any,
    regtyep: any,
    course: any,
    studentid: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/xeroxCopy",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        colleges: college,
        examAnnouncement: exam,
        registrationType: regtyep,
        courses: course,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  semesterregistrationpg(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    college: any,
    program: any,
    studentid: any,
    regtyep: any,
    courselist: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createSemesterRegistrationPG",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        colleges: college,
        programmes: program,
        studentId: studentid,
        registrationType: regtyep,
        coursesList: courselist
      },
      { observe: "response" }
    );
  }

  hostelPayemnt(
    academic: any,
    college: any,
    program: any,
    semester: any,
    programyear: any,
    studentid: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/payableAmount",
      {
        academicYear: academic,
        college: college,
        programmes: program,
        semester: semester,
        programmeYear: programyear,
        student: studentid
      },
      { observe: "response" }
    );
  }

  getGradePoints(
    programyear: any,
    semester: any,
    studentid: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getStudentGradePoint",
      {
        programmeYear: programyear,
        semester: semester,
        exStudentId: studentid,
      },
      { observe: "response" }
    );
  }


  studenteligibilty(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    program: any,
    studentid: any,


  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/semesterRegistrationEligibility",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        programmes: program,
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  getsupplementarycourse(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    exam: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getSupplementaryCourses",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        examAnnouncement: exam
      },
      { observe: "response" }
    );
  }

  getreevaluationcourse(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    exam: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getEvaluatedCourseList",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        examAnnouncement: exam
      },
      { observe: "response" }
    );
  }

  getreevaluationcoursepg(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    degree: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getPgEvaluatedCourseList",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        degree:degree
      },
      { observe: "response" }
    );
  }

  getNRIfeeamount(
    semester: any,
    programyear: any,
    prpgram: any,
    degree: any,
    studentid: any,
    academicyear: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/common/getNRIStudentFeeDetails",
      {
        semester: semester,
        programmeyear: programyear,
        programmes: prpgram,
        degree: degree,
        studentId: studentid,
        academicYear: academicyear
      },
      { observe: "response" }
    );
  }

  getphotocopycourse(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    exam: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCourseForXerox",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        examAnnouncement: exam
      },
      { observe: "response" }
    );
  }

  getphotocopycoursepg(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    degree: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getPgCourseForXerox",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  getreregistercourse(
    academic: any,
    programyear: any,
    semester: any,
    program: any,
    studentid: any,
    exam: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getReRegisterCourses",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentId: studentid,
        examAnnouncement: exam
      },
      { observe: "response" }
    );
  }

  studenteligibiltypg(
    academic: any,
    programyear: any,
    semester: any,
    degree: any,
    program: any,
    studentid: any,


  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/semesterRegistrationEligibilityPG",
      {
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
        degree: degree,
        programmes: program,
        studentId: studentid,
      },
      { observe: "response" }
    );
  }

  examonProgramandacdemicanddegree(
    academic: any,
    program: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getExamAnnouncementDetails",
      {
        academicyear: academic,
        programmes: program,
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getAllocationDetails(
    academic: any,
    exam: any,
    college: any,
    evaltype: any,
    empolyyeid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getEvaluatorSelection",
      {
        academicYear: academic,
        examAnnouncement: exam,
        colleges: college,
        evaluationType: evaltype,
        exEmployeeId: empolyyeid,
      },
      { observe: "response" }
    );
  }

  getEvaluationType(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getEvaluationType",
      {},
      { observe: "response" }
    );
  }

  getCourseList(exam: any, program: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getAllCourseDetailsFromExamAnnouncement",
      {
        examAnnouncement: exam,
        programmes: program,
      },
      { observe: "response" }
    );
  }

  exmhallonCollege(college: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/roomMasterOnCollege",
      {
        clg: college,
      },
      { observe: "response" }
    );
  }

  updatetimetable(
    timetableid: any,
    startdate: any,
    starttime: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateExamTimeTable",
      {
        exTimeTableId: timetableid,
        exStartDate: startdate,
        exStartTime: starttime,
        user: user,
      },
      { observe: "response" }
    );
  }

  getExamtimetableDetails(
    exam: any,
    academicyear: any,
    program: any,
    regtype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getExamTimeTable",
      {
        examannouncement: exam,
        academicYear: academicyear,
        programmes: program,
        registrationType: regtype,
      },
      { observe: "response" }
    );
  }

  submifinaltimetable(
    academicyear: any,
    program: any,
    exam: any,
    identifier: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/generateTimetable",
      {
        academicYear: academicyear,
        programmes: program,
        examannouncement: exam,
        exIdentifier: identifier,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitExamtimetable(
    exam: any,
    startdate: any,
    active: any,
    enddate: any,
    starttime: any,
    endtime: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createExamTimeTable",
      {
        examannouncement: exam,
        exStartDate: startdate,
        exIsActive: active,
        exEndDate: enddate,
        exStartTime: starttime,
        exEndTime: endtime,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitExamAttendance(
    academic: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    studentidfrom: any,
    studentidto: any,
    room: any,
    regtype: any,
    user: any,
    code: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createExamAttendance",
      {
        academicYear: academic,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        exStudentIdFrom: studentidfrom,
        exStudentIdTo: studentidto,
        roomId: room,
        registrationType: regtype,
        user: user,
        exCoursesCode: code,
      },
      { observe: "response" }
    );
  }

  getAvaliableroomdetails(
    academic: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    regtype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/checkRoomCapacity",
      {
        academicYear: academic,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        registrationType: regtype,
      },
      { observe: "response" }
    );
  }

  checkAttendance(
    academic: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/checkAttendance",
      {
        academicYear: academic,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }

  checkgradereport(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    academicyear: any,
    section: any,
    reporttype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/checkReportStatus",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        section: section,
        reportType: reporttype,
      },
      { observe: "response" }
    );
  }

  submitprogressreportgenerate(
    college: any,
    semester: any,
    degree: any,
    program: any,
    exam: any,
    programyear: any,
    academicyear: any,
    remark: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateSemesterReport",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        exRemark: remark,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitprogressreportregenerate(
    college: any,
    semester: any,
    degree: any,
    program: any,
    exam: any,
    programyear: any,
    academicyear: any,
    remark: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateSemesterReport",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        exRemark: remark,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitgradereportgenerate(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    academicyear: any,
    section: any,
    remark: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateGradeReport",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        section: section,
        exRemark: remark,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitgradereportregenerate(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    academicyear: any,
    section: any,
    remark: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/generateGradeReport",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        section: section,
        exRemark: remark,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }

  submitmodifyExamtimetable(
    exam: any,
    startdate: any,
    active: any,
    enddate: any,
    starttime: any,
    endtime: any,
    regtype: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createExamTimeTable",
      {
        examannouncement: exam,
        exStartDate: startdate,
        exIsActive: active,
        exEndDate: enddate,
        exStartTime: starttime,
        exEndTime: endtime,
        registrationType: regtype,
        user: user,
      },
      { observe: "response" }
    );
  }
  programOnDegreeandcollege(
    degree: any,
    college: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/programmesOnCollegeAndDegree",
      {
        degre: degree,
        clg: college,
      },
      { observe: "response" }
    );
  }

  createStudentProfile(
    college: any,
    degree: any,
    program: any,
    category: any,
    programyear: any,
    semester: any,
    academicyaer: any,
    admissionsoure: any,
    passedoutyear: any,
    loginname: any,
    password: any,
    active: any,
    adhaarnumber: any,
    bloodgroup: any,
    dateofbirth: any,
    email: any,
    gender: any,
    firstname: any,
    middlename: any,
    lastname: any,
    mobile: any,
    mothertongue: any,
    nationality: any,
    religion: any,
    ruralurban: any,
    speciallyabled: any,
    state: any,
    fatheremail: any,
    fathermobile: any,
    fathername: any,
    guardian: any,
    mothername: any,
    occupation: any,
    board: any,
    documentphotocopy: any,
    institutename: any,
    marks: any,
    medium: any,
    passingyear: any,
    user: any,
    thesis: any,
    scheme: any,
    income: any,
    locallangpassingyear: any,
    subcaste:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createStudentProfile",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        category: category,
        programmeyear: programyear,
        semester: semester,
        academicyear: academicyaer,
        admissionSource: admissionsoure,
        passedOutYear: passedoutyear,
        loginName: loginname,
        password: password,
        exIsActive: active,
        adhaarNumber: adhaarnumber,
        bloodGroup: bloodgroup,
        dateOfBirth: dateofbirth,
        email: email,
        gender: gender,
        firstName: firstname,
        middleName: middlename,
        lastName: lastname,
        mobile: mobile,
        motherTounge: mothertongue,
        nationality: nationality,
        religion: religion,
        ruralUrban: ruralurban,
        speciallyAbled: speciallyabled,
        state: state,
        fatherEmail: fatheremail,
        fatherMobile: fathermobile,
        fatherName: fathername,
        guardian: guardian,
        motherName: mothername,
        occupation: occupation,
        board: board,
        documentPhotoCopy: documentphotocopy,
        instituteName: institutename,
        marks: marks,
        medium: medium,
        passingyear: passingyear,
        user: user,
        thesis: thesis,
        scheme: scheme,
        income: income,
        exLocalLangpassingYear: locallangpassingyear,
        subCaste:subcaste
      },
      { observe: "response" }
    );
  }

  createexam(
    academicyear: any,
    programyear: any,
    program: any,
    semester: any,
    degree: any,
    names: any,
    desc: any,
    active: any,
    examinationperiod: any,
    instructionalperiod: any,
    practicalperiod: any,
    examstartdate: any,
    examenddate: any,
    practicalexamstartdate: any,
    practicalexamenddate: any,
    sembreakstartdate: any,
    sembreakenddate: any,
    semstartdate: any,
    semenddate: any,
    locallangname: any,
    islive: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createExamAnnouncement",
      {
        academicyear: academicyear,
        programmeyear: programyear,
        programmes: program,
        semester: semester,
        degree: degree,
        exName: names,
        exDescription: desc,
        exIsActive: active,
        exExaminationPeriod: examinationperiod,
        exInstructionalPeriod: instructionalperiod,
        exPracticalPeriod: practicalperiod,
        exExamDetailsStartDate: examstartdate,
        exExamDetailsEndDate: examenddate,
        exPracticalExamStartDate: practicalexamstartdate,
        exPracticalExamEndDate: practicalexamenddate,
        exSemesterBreakStartDate: sembreakstartdate,
        exSemesterBreakEndDate: sembreakenddate,
        exSemesterDetailsStartDate: semstartdate,
        exSemesterDetailsEndDate: semenddate,
        exLocalLangName: locallangname,
        exIsLive: islive,
        user: user,
      },
      { observe: "response" }
    );
  }

  updateexam(
    names: any,
    program: any,
    active: any,
    examstartdate: any,
    examenddate: any,
    practicalexamstart: any,
    practicalexamendadate: any,
    semstartdate: any,
    semenddate: any,
    user: any,
    islive: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateExamAnnouncement",
      {
        exName: names,
        programmes: program,
        exIsActive: active,
        exExamDetailsStartDate: examstartdate,
        exExamDetailsEndDate: examenddate,
        exPracticalExamStartDate: practicalexamstart,
        exPracticalExamEndDate: practicalexamendadate,
        exSemesterDetailsStartDate: semstartdate,
        exSemesterDetailsEndDate: semenddate,
        user: user,
        exIsLive: islive,
        id: id,
      },
      { observe: "response" }
    );
  }
  //student profile get
  getStudentProfile(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStudentProfileByIds",
      {
        id: id,
      },
      { observe: "response" }
    );
  }

  //student profile get
  getStudentRecord(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getRecordByStudentsID",
      {
        id: id,
      },
      { observe: "response" }
    );
  }

  //update student profile
  updateStudentProfile(
    college: any,
    category: any,
    actualcategory: any,
    passedoutyear: any,
    active: any,
    adhaaarno: any,
    blooadgroup: any,
    dateofbirth: any,
    localdob: any,
    email: any,
    gender: any,
    firstname: any,
    middlename: any,
    lastname: any,
    locallangname: any,
    mobile: any,
    mothertongue: any,
    nationality: any,
    religion: any,
    ruralurban: any,
    speciallyabled: any,
    state: any,
    fatheremail: any,
    fathermobile: any,
    fathername: any,
    guardian: any,
    mothername: any,
    occupation: any,
    board: any,
    institutename: any,
    marks: any,
    medium: any,
    id: any,
    passingyear: any,
    hostel: any,
    fatherlocal: any,
    motherlocal: any,
    user: any,
    thesiss: any,
    scheme: any,
    income: any,
    localpassingyear: any,
    alumni: any,
    ssubcaste:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateStudentProfile",
      {
        colleges: college,
        category: category,
        actualcategory: actualcategory,
        passedOutYear: passedoutyear,
        exIsActive: active,
        adhaarNumber: adhaaarno,
        bloodGroup: blooadgroup,
        dateOfBirth: dateofbirth,
        exLocalLangDob: localdob,
        email: email,
        gender: gender,
        firstName: firstname,
        middleName: middlename,
        lastName: lastname,
        exLocalLangName: locallangname,
        mobile: mobile,
        motherTounge: mothertongue,
        nationality: nationality,
        religion: religion,
        ruralUrban: ruralurban,
        speciallyAbled: speciallyabled,
        state: state,
        fatherEmail: fatheremail,
        fatherMobile: fathermobile,
        fatherName: fathername,
        guardian: guardian,
        motherName: mothername,
        occupation: occupation,
        board: board,
        instituteName: institutename,
        marks: marks,
        medium: medium,
        id: id,
        passingyear: passingyear,
        hostel: hostel,
        exLocalLangFatherName: fatherlocal,
        exLocalLangMotherName: motherlocal,
        user: user,
        thesis: thesiss,
        scheme: scheme,
        income: income,
        exLocalLangpassingYear: localpassingyear,
        exIsAlumni: alumni,
        subCaste:ssubcaste
      },
      { observe: "response" }
    );
  }


  updateStudentProfilebystudent(
    income: any,
    lastname: any,
    speciallydisabled: any,
    fathername: any,
    locallang: any,
    gender: any,
    occupation: any,
    adhaarno: any,
    medium: any,
    fathermobile: any,
    institute: any,
    bloodgroup: any,
    doblocal: any,
    ruralurban: any,
    motherlocal: any,
    id: any,
    state: any,
    guardian: any,
    email: any,
    mobile: any,
    mothername: any,
    dateofb: any,
    marks: any,
    fatheremail: any,
    localfathername: any,
    religion: any,
    firstname: any,
    passingyear: any,
    nationality: any,
    middlename: any,
    category: any,
    mothertongue: any,
    board: any,
    active: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateStudentRecords",
      {
        income: income,
        lastName: lastname,
        speciallyAbled: speciallydisabled,
        fatherName: fathername,
        exLocalLangName: locallang,
        gender: gender,
        occupation: occupation,
        adhaarNumber: adhaarno,
        medium: medium,
        fatherMobile: fathermobile,
        instituteName: institute,
        bloodGroup: bloodgroup,
        exLocalLangDob: doblocal,
        ruralUrban: ruralurban,
        exLocalLangMotherName: motherlocal,
        id: id,
        state: state,
        guardian: guardian,
        email: email,
        mobile: mobile,
        motherName: mothername,
        dateOfBirth: dateofb,
        marks: marks,
        fatherEmail: fatheremail,
        exLocalLangFatherName: localfathername,
        religion: religion,
        firstName: firstname,
        passingyear: passingyear,
        nationality: nationality,
        middleName: middlename,
        category: category,
        motherTounge: mothertongue,
        board: board,
        exIsActive: active
      },
      { observe: "response" }
    );
  }

  //staff profile get
  createstaffprofile(
    address: any,
    adhaarno: any,
    bankaccoutno: any,
    bankname: any,
    blooadgroup: any,
    city: any,
    college: any,
    dob: any,
    doj: any,
    degree: any,
    discipline: any,
    dept: any,
    designation: any,
    email: any,
    empid: any,
    firstname: any,
    ifsccode: any,
    lastname: any,
    loginname: any,
    middlename: any,
    mobile: any,
    pannumber: any,
    password: any,
    pincode: any,
    skill: any,
    stafftyep: any,
    staff: any,
    gender: any,
    active: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/createStaff",
      {
        address: address,
        adhaarNumber: adhaarno,
        bankAccountNo: bankaccoutno,
        bankName: bankname,
        bloodGroup: blooadgroup,
        city: city,
        colleges: college,
        dateOfBirth: dob,
        dateOfJoining: doj,
        degree: degree,
        discipline: discipline,
        department: dept,
        designation: designation,
        email: email,
        employeeId: empid,
        firstName: firstname,
        ifscCode: ifsccode,
        lastName: lastname,
        loginName: loginname,
        middleName: middlename,
        mobile: mobile,
        panNumber: pannumber,
        password: password,
        pincode: pincode,
        skill: skill,
        stafftype: stafftyep,
        state: staff,
        gender: gender,
        exIsActive: active,
        user: user,
      },
      { observe: "response" }
    );
  }

  //student profile get
  updatestaffprofile(
    address: any,
    adhaarno: any,
    bankaccoutno: any,
    bankname: any,
    blooadgroup: any,
    city: any,
    college: any,
    dob: any,
    doj: any,
    discipline: any,
    designation: any,
    dept: any,
    stafftyep: any,
    email: any,
    empid: any,
    firstname: any,
    ifsccode: any,
    lastname: any,
    middlename: any,
    mobile: any,
    pannumber: any,
    pincode: any,
    skill: any,
    gender: any,
    state: any,
    id: any,
    active: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateStaff",
      {
        address: address,
        adhaarNumber: adhaarno,
        bankAccountNo: bankaccoutno,
        bankName: bankname,
        bloodGroup: blooadgroup,
        city: city,
        colleges: college,
        dateOfBirth: dob,
        dateOfJoining: doj,
        discipline: discipline,
        designation: designation,
        department: dept,
        stafftype: stafftyep,
        email: email,
        employeeId: empid,
        firstName: firstname,
        ifscCode: ifsccode,
        lastName: lastname,
        middleName: middlename,
        mobile: mobile,
        panNumber: pannumber,
        pincode: pincode,
        skill: skill,
        gender: gender,
        state: state,
        id: id,
        exIsActive: active,
        user: user,
      },
      { observe: "response" }
    );
  }

  //staff profile get
  getStaffByid(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getStaffByIds",
      {
        id: id,
      },
      { observe: "response" }
    );
  }

  courseMapping(
    type: any,
    college: any,
    degree: any,
    program: any,
    sem: any,
    staffpersonal: any,
    stafftype: any,
    programyear: any,
    active: any,
    exam: any,
    academicyear: any,
    course: any,
    section: any,
    retype: any,
    id: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "TeacherCourseMapping",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: sem,
        staffpersnoal: staffpersonal,
        staffType: stafftype,
        programmeYear: programyear,
        exIsActive: active,
        examAnnouncement: exam,
        academicYear: academicyear,
        courses: course,
        section: section,
        registrationType: retype,
        id: id,
        user: user,
      },
      { observe: "response" }
    );
  }

  courseMappingpg(
    type: any,
    college: any,
    degree: any,
    program: any,
    sem: any,
    staffpersonal: any,
    stafftype: any,
    programyear: any,
    active: any,
    academicyear: any,
    course: any,
    section: any,
    id: any,
    user: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "TeacherCourseMappingForPG",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: sem,
        staffpersnoal: staffpersonal,
        staffType: stafftype,
        programmeYear: programyear,
        exIsActive: active,
        academicYear: academicyear,
        courses: course,
        section: section,
        id: id,
        user: user,
      },
      { observe: "response" }
    );
  }

  feeMaster(
    type: any,
    feeamount: any,
    college: any,
    degree: any,
    program: any,
    semester: any,
    category: any,
    feetype: any,
    programyear: any,
    active: any,
    feename: any,
    academic: any,
    dacno: any,
    id: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/" + type + "FeeMaster",
      {
        feeAmount: feeamount,
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        category: category,
        feeType: feetype,
        programmeYear: programyear,
        exIsActive: active,
        exFeeName: feename,
        academicYear: academic,
        exAbAcDac: dacno,
        id: id,
        user: user,
      },
      { observe: "response" }
    );
  }


  miscellaneusFee(
    type: any,
    academicyear: any,
    college: any,
    programyear: any,
    semester: any,
    user: any,
    feeamount: any,
    feename: any,
    abacno: any,
    degree: any,
    id: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/miscellaneousfee/" + type,
      {
        academicYear: academicyear,
        colleges: college,
        programmeYear: programyear,
        semester: semester,
        user: user,
        feeAmount: feeamount,
        exFeeName: feename,
        exAbAcDac: abacno,
        degree: degree,
        id: id
      },
      { observe: "response" }
    );
  }

  penaltyConfiguration(
    type: any,
    amount: any,
    programyear: any,
    semester: any,
    degree: any,
    startdate: any,
    enddate: any,
    duedate: any,
    active: any,
    program: any,
    academic: any,
    id: any,
    user: any,
    college:any,
    category:any,
    abacno:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/" + type + "Penalty",
      {
        exIncAmount: amount,
        programmeyear: programyear,
        semester: semester,
        degree: degree,
        exStartDate: startdate,
        exEndDate: enddate,
        exDueDate: duedate,
        exIsActive: active,
        programme: program,
        academicYear: academic,
        id: id,
        user: user,
        college:college,
        category :category,
        exAbAcDac :abacno
      },
      { observe: "response" }
    );
  }

  feeCreate(
    type: any,
    college: any,
    feetyep: any,
    abacdac: any,
    name: any,
    degree: any,
    active: any,
    program: any,
    id: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/" + type + "FeeCategory",
      {
        colleges: college,
        feetype: feetyep,
        exAbAcDac: abacdac,
        exName: name,
        degree: degree,
        exIsActive: active,
        programmes: program,
        id: id,
        user: user,
      },
      { observe: "response" }
    );
  }


  upadatemarks(
    academic: any,
    course: any,
    degree: any,
    average: any,
    studentid: any,
    program: any,
    college: any,
    remark: any,
    programyear: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/updateStudentInternalMarks",
      {
        academicYear: academic,
        courses: course,
        degree: degree,
        exAverage: average,
        exStudentId: studentid,
        programmes: program,
        colleges: college,
        exRemarks: remark,
        programmeyear: programyear,
        user: user,
      },
      { observe: "response" }
    );
  }

  sendPhotocopyMail(
    barcode: any,
    studentid: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/sendPhotocopy",
      {
        barcode: barcode,
        studentId: studentid,
      },
      { observe: "response" }
    );
  }

  //student details get
  getStudent(
    academicyear: any,
    college: any,
    program: any,
    degree: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/studentForCounsellor",
      {
        academicYear: academicyear,
        colleges: college,
        programmes: program,
        degree: degree,
      },
      { observe: "response" }
    );
  }

  //consellor details get
  getconsellor(
    college: any,
    degree: any,
    program: any,
    staff: any,
    stafftype: any,
    academicyear: any,
    studentlist: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/counsellorAllocation/create",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        staffId: staff,
        stafftype: stafftype,
        academicYear: academicyear,
        studentList: studentlist,
      },
      { observe: "response" }
    );
  }

  getconsellorData(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/counsellorAllocation/get",
      {},
      { observe: "response" }
    );
  }

  approveDean(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/deanApprove",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicyear: academicyear,
        programmeyear: programyear,
        semester: semester,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }

  disapproveDean(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/deanDisApprove",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicyear: academicyear,
        programmeyear: programyear,
        semester: semester,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }

  getStudentDetailslibrary(
    college: any,
    program: any,
    dgeree: any,
    academic: any,
    programyear: any,
    semester: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/studentListForLibrary",
      {
        colleges: college,
        programmes: program,
        degree: dgeree,
        academicYear: academic,
        programmeYear: programyear,
        semester: semester,
      },
      { observe: "response" }
    );
  }
 
  approvelibrary(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/approveLibrary",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        studentList: studentlist,
        libraryuser: user,
      },
      { observe: "response" }
    );
  }
  approveac(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/approveAssistantComptroller",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        studentList: studentlist,
        assistantComptrollerUser: user,
      },
      { observe: "response" }
    );
  }

  approvear(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/approveAssistantRegistrar",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        studentList: studentlist,
        assistantRegistrarUser: user,
      },
      { observe: "response" }
    );
  }
  disapprovelib(
    degree: any,
    college: any,
    academicyear: any,
    programyear: any,
    semester: any,
    program: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/DisApproveLibrary",
      {
        degree: degree,
        colleges: college,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }
  disapproveac(
    degree: any,
    college: any,
    academicyear: any,
    programyear: any,
    semester: any,
    program: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/disApproveAssistantComptroller",
      {
        degree: degree,
        colleges: college,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }
  disapprovear(
    degree: any,
    college: any,
    academicyear: any,
    programyear: any,
    semester: any,
    program: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/disApproveAssistantRegistrar",
      {
        degree: degree,
        colleges: college,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }
  approvehostel(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/approveHostel",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        studentList: studentlist,
        hosteluser: user,
      },
      { observe: "response" }
    );
  }

  disapproveHostel(
    degree: any,
    college: any,
    academicyear: any,
    programyear: any,
    semester: any,
    program: any,
    studentlist: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/DisApproveHostel",
      {
        degree: degree,
        colleges: college,
        academicYear: academicyear,
        programmeYear: programyear,
        semester: semester,
        programmes: program,
        studentList: studentlist,
        user: user,
      },
      { observe: "response" }
    );
  }

  getTeachercoursemap(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getTeacherCourseMappings",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getTeachercoursemap1(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getTeacherCourseMappingDetails",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getfeeMaster(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/feemaster/get",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getMiscelaneousFee(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/miscellaneousfee/get",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  getfeecreatename(degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getFeeCategoryOnDegreeOnly",
      {
        degree: degree,
      },
      { observe: "response" }
    );
  }

  submitStatus(ticketnumber: any, complaintstatus: any, remark: any, user: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/updateComplaintStatus",
      {
        exTicketNumber: ticketnumber,
        complaintStatus: complaintstatus,
        exremark: remark,
        user: user,
      },
      { observe: "response" }
    );
  }

  getComplaintData(complaint: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/getComplainListOnStatus",
      {
        complaintStatus: complaint,
      },
      { observe: "response" }
    );
  }

  getFeeDetails(studentid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getTransacationDetails?studentid=" + studentid,
      {
      },
      { observe: "response" }
    );
  }
  getCourseDetails(studentid: any, scheme: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCurrentCourses",
      {
        studentId: studentid,
        scheme: scheme
      },
      { observe: "response" }
    );
  }

  getPaymentDetails(degree: any, college: any, student: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/miscellaneousfee/college/get",
      {
        degree: degree,
        colleges: college,
        student: student
      },
      { observe: "response" }
    );
  }

  getonlinepayinit(academic: any, programyear: any, college: any, program: any, semester: any, studntid: any, degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/onlinePayInit",
      {
        academicYear: academic,
        programmeYear: programyear,
        colleges: college,
        programmes: program,
        semester: semester,
        exStudentId: studntid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  getonlinepayinitpg(academic: any, programyear: any, college: any, program: any, semester: any, studntid: any, degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/pgOnlinePayInit",
      {
        academicYear: academic,
        programmeYear: programyear,
        colleges: college,
        programmes: program,
        semester: semester,
        exStudentId: studntid,
        degree: degree
      },
      { observe: "response" }
    );
  }

  getonlinepayinitforview(academic: any, programyear: any, college: any, program: any, semester: any, degree: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/viewOnlinePayInit",
      {
        academicYear: academic,
        programmeYear: programyear,
        colleges: college,
        programmes: program,
        semester: semester,
        degree: degree
      },
      { observe: "response" }
    );
  }

  getTicketDetails(studentid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/getComplainHistory",
      {
        studentId: studentid
      },
      { observe: "response" }
    );
  }

  getRefunddata(orderid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/helpDesk/getPaymentDetails",
      {
        orderId: orderid,
      },
      { observe: "response" }
    );
  }

  getTeacherCourseData(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getCourseMappingOnIDs",
      {
        id: id,
      },
      { observe: "response" }
    );
  }
  getTeacherCourseDatapg(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getTeacherCoursesMappingOnIDForPG",
      {
        id: id,
      },
      { observe: "response" }
    );
  }
  getFeeMasterBasedonid(id: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getFeeMasterOnId",
      {
        id: id,
      },
      { observe: "response" }
    );
  }

  getPenaltybasedID(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/postexam/getPenalties",
      {
      },
      { observe: "response" }
    );
  }

  semregistration(
    type: any,
    active: any,
    regstartdate: any,
    regenddate: any,
    registrationtyep: any,
    exchangeofcoursedate: any,
    exam: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "SemesterConfiguration",
      {
        exIsActive: active,
        exRegistrationStartDate: regstartdate,
        exRegistrationEndDate: regenddate,
        registrationType: registrationtyep,
        exChangeOfCourseDate: exchangeofcoursedate,
        examAnnouncement: exam,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  internalmarksconfig(
    type: any,
    active: any,
    academicyaera:any,
    program:any,
    exam: any,
    registrationtyep: any,
    componentmaster:any,
    regstartdate: any,
    regenddate: any,
    user: any,
    id: any,
    degree:any,
    coursetype:any,
    college:any,
    course:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "InternalMarksDateConfig",
      {
        exIsActive:active,
        academicYear:academicyaera,
        programme:program,
        examination:exam,
        registrationType:registrationtyep,
        exComponentMaster:componentmaster,
        startDate:regstartdate,
        endDate:regenddate,
        user:user,
        id:id,
        degree:degree,
        coursetype:coursetype,
        college:college,
        courses:course
      },
      { observe: "response" }
    );
  }

  internalmarksconfigpg(
    type: any,
    active: any,
    academicyaera:any,
    registrationtyep: any,
    componentmaster:any,
    regstartdate: any,
    regenddate: any,
    user: any,
    id: any,
    degree:any,
    coursetype:any,
    college:any,
    course:any,
    programyear:any,
    sem:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/PgInternalMarksDateConfig/" +type ,
      {
        exIsActive:active,
        academicYear:academicyaera,
        registrationType:registrationtyep,
        exComponentMaster:componentmaster,
        startDate:regstartdate,
        endDate:regenddate,
        user:user,
        id:id,
        degree:degree,
        coursetype:coursetype,
        college:college,
        courses:course,
        programYear:programyear,
        semester:sem
      },
      { observe: "response" }
    );
  }

  semregistrationpg(
    type: any,
    active: any,
    regstartdate: any,
    regenddate: any,
    exchangeofcoursedate: any,
    program: any,
    programyear: any,
    semester: any,
    academicyear: any,
    degree: any,
    droppingcourse: any,
    qualifyingexam: any,
    user: any,
    penaltyenddate:any,
    id:any,
    regtype:any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "PGConfiguration",
      {
        exIsActive: active,
        exRegistrationStartDate: regstartdate,
        exRegistrationEndDate: regenddate,
        exChangeOfCourseDate: exchangeofcoursedate,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
        academicYear: academicyear,
        degree: degree,
        exDroppingCourseDate: droppingcourse,
        exDateOfQualifyingExam: qualifyingexam,
        user: user,
        exDeanPenaltyEndDate:penaltyenddate,
        id:id,
        registrationType:regtype
      },
      { observe: "response" }
    );
  }

  semregistrationget(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getSemesterConfigurations",
      {},
      { observe: "response" }
    );
  }

  internalmarksget(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/getInternalMarksDateConfig",
      {},
      { observe: "response" }
    );
  }

  internalmarksgetpg(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/PgInternalMarksDateConfig/get",
      {},
      { observe: "response" }
    );
  }

  getpgconfiguration(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getPGConfigurations",
      {},
      { observe: "response" }
    );
  }

  getAllInvigilator(): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getAllInvigilators",
      {},
      { observe: "response" }
    );
  }

  submitInvigilatordetails(
    type: any,
    academicyear: any,
    college: any,
    exam: any,
    staff: any,
    stafftype: any,
    active: any,
    startdate: any,
    starttime: any,
    endtime: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/" + type + "InvigilatorAllocation",
      {
        academicYear: academicyear,
        colleges: college,
        examAnnouncement: exam,
        staff: staff,
        staffType: stafftype,
        exIsActive: active,
        exStartDate: startdate,
        exStartTime: starttime,
        exEndTime: endtime,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  getStartDates(
    academic: any,
    program: any,
    exam: any,
    retype: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/getExamTimeTable",
      {
        academicYear: academic,
        programmes: program,
        examannouncement: exam,
        registrationType: retype,
      },
      { observe: "response" }
    );
  }

  getHostel(
    type: any,
    roomname: any,
    college: any,
    roomno: any,
    roomcapacity: any,
    isactive: any,
    user: any,
    id: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/" + type + "RoomMaster",
      {
        roomName: roomname,
        colleges: college,
        roomNumber: roomno,
        roomCapacity: roomcapacity,
        exIsActive: isactive,
        user: user,
        id: id,
      },
      { observe: "response" }
    );
  }

  //dowload examtimetable
  downloadExamtimetable(
    academicyear: any,
    programyear: any,
    semester: any,
    regtype: any,
    remark: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/academic/getExamTimeTableConsolidatedPdf",
      {
        academicYear: academicyear,
        programmeyear: programyear,
        semster: semester,
        registrationType: regtype,
        remark: remark,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downloadRemuneration(
    academicyear: any,
    program: any,
    exam: any,
    course: any,
    empid: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateRenumarationPdf",
      {
        academicYear: academicyear,
        programmes: program,
        examAnnouncement: exam,
        courses: course,
        employeeId: empid,
      },
      { responseType: "blob" as "json" }
    );
  }

 //dowload internalmarks
 downloadinternalmarksrepo(
  academicyear: any,
  course: any,
  degree:any,
  program: any,
  college:any,
  programyear:any,
  semester: any,
  component: any
) {
  return this.http.post(
    environment.endpoint + "/excel/download/componentMarksOnComponentId",
    {
      academicYear: academicyear,
      course: course,
      degree: degree,
      programme: program,
      college:college,
      programYear:programyear,
      semester:semester,
      exComponentMaster:component   
    },
    { responseType: "blob" as "json" }
  );
}

downloadcomponentmarksrepo(
  academicyear: any,
  course: any,
  degree:any,
  program: any,
  college:any,
  programyear:any,
  semester: any,
) {
  return this.http.post(
    environment.endpoint + "/excel/download/componentMarks",
    {
      academicYear: academicyear,
      course: course,
      degree: degree,
      programme: program,
      college:college,
      programYear:programyear,
      semester:semester
    },
    { responseType: "blob" as "json" }
  );
}

  //dowload remuneration
  downloadgradepdf(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    academicyear: any,
    section: any,
    user: any,
    regtype: any
  ) {
    return this.http.post(
      environment.endpoint + "/postexam/generateGradeReportPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        sectionid: section,
        user: user,
        registrationType: regtype,
      },
      { responseType: "blob" as "json" }
    );
  }


  //dowload remuneration
  downloadapprovegradepdf(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,

  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePreviewOfGradeCardPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
      },
      { responseType: "blob" as "json" }
    );
  }


  //dowload remuneration
  downloadProgreespdfStud(
    college: any,
    semester: any,
    degree: any,
    program: any,
    studentid: any,
    exam: any,
    programyear: any,
    academicyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/postexam/generatesemesterProgessReportPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        exStudentId: studentid,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downlodinternalmarks(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    section: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateInternalPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
        section: section,
      },
      { responseType: "blob" as "json" }
    );
  }

  downlodcomponentMarksFaculty(
    academicyear: any,
    course: any,
    degree: any,
    program: any,
    staff: any,
    programyear: any,
    semester: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/componentMarksforStaff",
      {
       academicYear: academicyear,
  course: course,
  degree: degree,
  programme: program,
  staff:staff,
  programYear:programyear,
  semester:semester
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadinternalstaff(
    academicyear: any,
    program: any,
    regtype:any,
    degree: any,
    course: any,
    programyear: any,
    componentmaster: any,
    section:any,
    semester:any,
    college:any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/DynamicExcelTemplate",
      {
        academicYear:academicyear,
        programme:program,
        registrationType:regtype,
        degree:degree,
        course:course,
        programYear:programyear,
        exComponentMaster:componentmaster,
        section:section,
        semester:semester,
        college:college
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downlodgradereport(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,

  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePreviewOfGradeCardPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeYear: programyear,
        semester: semester,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downlodpreview(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    section: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateInternalPreviewPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
        section: section,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downlodpreview1(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    section: any,
    component:any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateComponentMarksPreviewPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
        section: section,
        exComponentMaster:component
      },
      { responseType: "blob" as "json" }
    );
  }
  //dowload remuneration
  downloadCGPACertifcate(
    college: any,
    degree: any,
    program: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    fromdate: any,
    todate: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateOGPAPdf",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        exStudentId: studentid,
        academicYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateOfDeclarationKan: dateofdeclarationk,
        fromDate: fromdate,
        toDate: todate,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downloadPDCst(
    college: any,
    degree: any,
    program: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePdcDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        studentid: studentid,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downloadMigrationst(
    college: any,
    degree: any,
    program: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateMigrationDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        studentid: studentid,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadMigrationstpg(
    college: any,
    degree: any,
    program: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePgMigrationDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        studentid: studentid,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  generatebulkconvocation(
    degree: any,
    program: any,
    college: any,
    academicyear: any,
    user: any,
    dateofdeclaration: any,
    dateofdeclarationk: any
  ) {
    return this.http.post(
      environment.endpoint + "/certificate/generateBulkConvocationforUG",
      {
        degreeid: degree,
        programmesid: program,
        collegesid: college,
        enrollmentYear: academicyear,
        user: user,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  downloadstudentwise(
    college: any,
    degree: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    fromdate: any,
    todate: any,
    thesis: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateOGPAPdfforPG",
      {
        colleges: college,
        degree: degree,
        exStudentId: studentid,
        academicYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateOfDeclarationKan: dateofdeclarationk,
        fromDate: fromdate,
        toDate: todate,
        thesis: thesis,
        user: user
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadmastersheet(
    college: any,
    degree: any,
    studentid: any,
    user: any,
    fromdate:any,
    todate:any,
    finalvivadate:any,
    finalvivadatekan:any,
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateMasterSheetReportPG",
      {
        colleges:college,
        degree: degree,
        exStudentId: studentid,  
        user: user,
        fromDate:fromdate,
        toDate:todate,
        finalVivaDate:finalvivadate,
        finalVivaDatekan:finalvivadatekan

      },
      { responseType: "blob" as "json" }
    );
  }


  downloadstudentwisepdc(
    college: any,
    degree: any,
    studentid: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePGPdcDocx",
      {
        collegesid: college,
        degreeid: degree,
        studentid: studentid,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadgradesheet(
    college: any,
    semester: any,
    degree: any,
    course: any,
    programyear: any,
    academicyear: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateGradeCardPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        courses: course,
        programmeYear: programyear,
        academicYear: academicyear,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadRegistrationcard(
    college: any,
    semester: any,
    degree: any,
    studentid: any,
    programyear: any,
    academicyear: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateRegistrationCardForPGPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        exStudentId: studentid,
        programmeYear: programyear,
        academicYear: academicyear,
        user: user
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadStudentcard(
    college: any,
    semester: any,
    degree: any,
    programyear: any,
    academicyear: any,
    user: any,
    studentid: any,
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateStudentCardPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmeYear: programyear,
        academicYear: academicyear,
        user: user,
        exStudentId: studentid
      },
      { responseType: "blob" as "json" }
    );
  }

  downloademptygradesheet(
    college: any,
    semester: any,
    degree: any,
    course: any,
    programyear: any,
    academicyear: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateGradeCardPdfForPg",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        courses: course,
        programmeYear: programyear,
        academicYear: academicyear,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  donorcertificate(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    donorid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateDonarGoldMedelforCoolejicatesCertificate",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        donorid: donorid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  campusgoldmedal(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateCampusGoldMedelCertificate",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
      },
      { responseType: "blob" as "json" }
    );
  }


  //dowload remuneration
  universitygoldmedalUG(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateUniversityGoldMedelCertificate",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  campusgoldMedalPG(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateUniversityGoldMedelCertificate",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  universitygoldMedalPG(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateUniversityGoldMedelCertificateforPgStudents",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  donorgoldmedalcertificate(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    donorid: any,
    dateofdeclaration: any,
    yearofconvocation: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateDonarGoldMedelCertificate",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        donorid: donorid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  donorgoldmedalcertificateforpg(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any,
    donorid: any,

  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateDonarGoldMedelCertificateforPgStudents",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
        donorid: donorid
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload remuneration
  donorcertificatePG(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    yearofconvocation: any,
    donorid: any,

  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generatePgDonarGoldMedel",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        yearOfConvocation: yearofconvocation,
        donorid: donorid
      },
      { responseType: "blob" as "json" }
    );
  }


  generatebulkconvocationpg(
    degree: any,
    program: any,
    college: any,
    academicyear: any,
    user: any,
    dateofdeclaration: any,
    dateofdeclarationk: any
  ) {
    return this.http.post(
      environment.endpoint + "/certificate/generateBulkConvocationPG",
      {
        degreeid: degree,
        programmesid: program,
        collegesid: college,
        enrollmentYear: academicyear,
        user: user,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
      },
      { responseType: "blob" as "json" }
    );
  }

  generateconvocationUg(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    dateofdeclarationk: any
  ) {
    return this.http.post(
      environment.endpoint + "/certificate/generateconvocationcertificateDocx",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
      },
      { responseType: "blob" as "json" }
    );
  }

  generateconvocationPg(
    degree: any,
    program: any,
    academicyear: any,
    college: any,
    user: any,
    studentid: any,
    dateofdeclaration: any,
    dateofdeclarationk: any
  ) {
    return this.http.post(
      environment.endpoint +
      "/certificate/generateconvocationcertificateformaster",
      {
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        collegesid: college,
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload pdc
  downloadPDCbulk(
    college: any,
    degree: any,
    program: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateBulkPDCDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload migration
  downloadMigrationbulk(
    college: any,
    degree: any,
    program: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateBulkMigrationDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadMigrationbulkpg(
    college: any,
    degree: any,
    program: any,
    academicyear: any,
    scrutinize: any,
    dateofdeclaration: any,
    dateofdeclarationk: any,
    user: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePgBulkMigrationDocx",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        enrollmentYear: academicyear,
        scrutinize: scrutinize,
        dateOfDeclaration: dateofdeclaration,
        dateinKannada: dateofdeclarationk,
        user: user,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadRegistrationCard(
    college: any,
    semester: any,
    degree: any,
    program: any,
    studentid: any,
    programyear: any,
    academicyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateRegistrationCardPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        exStudentId: studentid,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadgpaorcgpa(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/overallMarksReport",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicyear: academicyear,
        programmeyear: programyear,
        semester: semester,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadgradereportgeneration(
    college: any,
    program: any,
    degree: any,
    academicyear: any,
    programyear: any,
    semester: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/SubmittedReport",
      {
        colleges: college,
        programmes: program,
        degree: degree,
        academicYear: academicyear,
        programmeyear: programyear,
        semster: semester
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadstudentadmission(
    college: any,
    degree: any,
    program: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/studentAdmission",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadgoldmedal(
    college: any,
    academicyear: any,
    degree: any,
    program: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/goldMedalReport.xlsx",
      {
        colleges: college,
        academicyear: academicyear,
        degree: degree,
        programmes: program,
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadsuppyattempts(
    studentid: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/backlogReportCount",
      {
        exStudentId: studentid,

      },
      { responseType: "blob" as "json" }
    );
  }


  downloadbacklog(
    studentid: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/backlogReportCount",
      {
        exStudentId: studentid,

      },
      { responseType: "blob" as "json" }
    );
  }


  downloadstudentstatic(
    college: any,
    academicyear: any,
    degree: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/studentStatistics.xlsx",
      {
        collages: college,
        academicyear: academicyear,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadstudentsemester(
    college: any,
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/semRegListReport.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadtopperlist(
    college: any,
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/topperListReport.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadsemesterregredstudent(
    college: any,
    academicyear: any,
    program: any,
    degree: any,
    programyera: any,
    semetsre: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/semesterRegistration",
      {
        colleges: college,
        academicYear: [academicyear],
        programmes: [program],
        degree: [degree],
        programmeYear: [programyera],
        semester: [semetsre],
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadinternalmarksstatus(
    college: any,
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/internalMarksStatus",
      {
        colleges: college,
        degree: degree,
        program: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadmarksuploadstatus(
    college: any,
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/marksUploadStatus",
      {
        colleges: college,
        degree: degree,
        program: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbookletuploadstatus(
    college: any,
    degree: any,
    program: any,
    exam: any,
    regtype: any,
    academicyear: any,
    course: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/answerBookletUploadStatus",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        examAnnouncement: exam,
        registrationType: regtype,
        academicYear: academicyear,
        course: course,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadevaluatorselection(
    college: any,
    exam: any,
    course: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/evaluatorSelectionStatus",
      {
        colleges: college,
        examAnnouncement: exam,
        courses: course,
        academicYear: academicyear
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadevaluationstatus(
    college: any,
    program: any,
    exam: any,
    course: any,
    academicyear: any,
    regtype:any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/evaluationStatus",
      {
        colleges: college,
        programmes: program,
        examAnnouncement: exam,
        courses: course,
        academicYear: academicyear,
        registrationType:regtype
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadreevaluationstatus(
    exam: any,
    academicyear: any,
    program: any,
    regtype:any,
    college: any, 
    course: any,  
   
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/BarcodeDigitalStatusInAdminforReEvaluation",
      {
        examAnnouncement: exam,
    academicYear: academicyear,
    programmes: program,
    registrationType: regtype,
    colleges : college,
    course:course
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbarcodereport(
    college: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/barcodeReport",
      {
        colleges: college,
        programmes: program,
        semester: semetsre,
        programmeyear: programyera,
        academicyear: academicyear
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadbarcodedigitalstatus(
    exam: any,
    academicyear: any,
    program: any,
    regtype: any,
    college: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/BarcodeDigitalStatusInAdmin",
      {
        examAnnouncement: exam,
        academicYear: academicyear,
        programmes: program,
        registrationType: regtype,
        colleges: college
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbookletallocationstatus(
    college: any,
    regtype: any,
    program: any,
    exam: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/bookletAllocationStatus",
      {
        colleges: college,
        registrationType: regtype,
        programmes: program,
        examAnnouncement: exam,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbookletallocationstatusrev(
    college: any,
    regtype: any,
    program: any,
    exam: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/rebookletAllocationStatus",
      {
        colleges: college,
        registrationType: regtype,
        programmes: program,
        examAnnouncement: exam,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbookletreallocationstatus(
    college: any,
    regtype: any,
    program: any,
    exam: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/bookletReAllocationStatus",
      {
        colleges: college,
        registrationType: regtype,
        programmes: program,
        examAnnouncement: exam,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadbookletreallocationstatusrev(
    college: any,
    regtype: any,
    program: any,
    exam: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/bookletAllocationReStatus",
      {
        colleges: college,
        registrationType: regtype,
        programmes: program,
        examAnnouncement: exam,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloaddigitallyevaluatedmarks(
    college: any,
    degree: any,
    program: any,
    exam: any,
    course: any,
    academicyear: any,
    semester: any,
    programyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/externalMarksQuestionWiseReport.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        examannouncement: exam,
        course: course,
        academicYear: academicyear,
        semster: semester,
        programmeYear: programyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloaddigitallyreevaluatedmarks(
    degree: any,
    program: any,
    course: any,
    exam: any,
    college: any,
    programyear: any,
    academicyear: any,
    semester: any,
   
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/reEvaluationExternalMarksQuestionWiseReport.xlsx",
      {
        degree: degree,
        programmes:program,
        course: course,
        examannouncement:exam,
        colleges: college,
        programmeYear:programyear,
        academicYear:academicyear,
        semster:semester
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadinvigilatorlist(
    college: any,
    academicyear: any,
    exam: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/invigilatorListReport",
      {
        colleges: college,
        academicyear: academicyear,
        examAnnouncement: exam,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadevaluationreport(
    college: any,
    degree: any,
    program: any,
    exam: any,
    regtype: any,
    academicyear: any,
    semester: any,
    programyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/evaluatedMarks",
      {
        collegeIds: college,
        degree: degree,
        programmes: program,
        examannouncement: exam,
        registrationType: regtype,
        academicYear: academicyear,
        semster: semester,
        programmeYear: programyear
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadevaluationreportrev(
    college: any,
    degree: any,
    program: any,
    exam: any,
    regtype: any,
    academicyear: any,
    semester: any,
    programyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/reEvaluatedMarks",
      {
        collegeIds: college,
        degree: degree,
        programmes: program,
        examannouncement: exam,
        registrationType: regtype,
        academicYear: academicyear,
        semster: semester,
        programmeYear: programyear
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadFeetrans(
    college: any,
    degree: any,
    program: any,
    semsetr: any,
    orderid: any,
    studentid: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    paymentstatus: any,
    tnsxrefno: any,
    category: any,
    feetype: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/transaction.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semsetr,
        orderId: orderid,
        studentId: studentid,
        programmeyear: programyear,
        startDate: startdate,
        endDate: enddate,
        academicyear: academicyear,
        paymentStatus: paymentstatus,
        txnReferenceNo: tnsxrefno,
        category: category,
        feeType: feetype
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadFeetranshistory(
    startdate: any,
    enddate: any,
    orderid: any,
    studentid: any,
    tnsxrefno: any,
    paymentstatus: any,
    academicyear: any,
    college: any,
    program: any,
    programyear: any,
    semsetr: any,
    category: any,
    feetype: any,
    degree: any,

  ) {
    return this.http.post(
      environment.endpoint + "/history/download/transactionhistory",
      {
        startDate: startdate,
        endDate: enddate,
        orderId: orderid,
        studentId: studentid,
        txnReferenceNo: tnsxrefno,
        paymentStatus: paymentstatus,
        academicyear: academicyear,
        colleges: college,
        programmes: program,
        programmeyear: programyear,
        semester: semsetr,
        category: category,
        feeType: feetype,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadNRIfee(
    college: any,
    degree: any,
    program: any,
    semsetr: any,
    orderid: any,
    studentid: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    paymentstatus: any,
    tnsxrefno: any,
    category: any,
    feetype: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/nri/transaction.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semsetr,
        orderId: orderid,
        studentId: studentid,
        programmeyear: programyear,
        startDate: startdate,
        endDate: enddate,
        academicyear: academicyear,
        paymentStatus: paymentstatus,
        txnReferenceNo: tnsxrefno,
        category: category,
        feeType: feetype
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadconsolidated(
    college: any,
    degree: any,
    program: any,
    semsetr: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    paymentstatus: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/ConsalidatedFeeReport.xlsx",
      {
        college: college,
        degree: degree,
        programmes: program,
        semester: semsetr,
        programmeyear: programyear,
        exStartDate: startdate,
        exEndDate: enddate,
        academicyear: academicyear,
        paymentStatus: paymentstatus
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadconsolidatedhistory(
    startdate: any,
    enddate: any,
    paymentstatus: any,
    academicyear: any,
    college: any,
    program: any,
    programyear: any,
    semsetr: any,
    degree: any,
  ) {
    return this.http.post(
      environment.endpoint + "/history/download/HistoryConsalidatedFeeReport",
      {
        exStartDate: startdate,
        exEndDate: enddate,
        paymentStatus: paymentstatus,
        academicyear: academicyear,
        college: college,
        programmes: program,
        programmeyear: programyear,
        semester: semsetr,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadabachistory(
    startdate: any,
    enddate: any,
    paymentstatus: any,
    academicyear: any,
    college: any,
    program: any,
    programyear: any,
    semsetr: any,
    degree: any,
  ) {
    return this.http.post(
      environment.endpoint + "/history/download/ABDACFeeReportHistory",
      {
        exStartDate: startdate,
        exEndDate: enddate,
        paymentStatus: paymentstatus,
        academicyear: academicyear,
        college: college,
        programmes: program,
        programmeyear: programyear,
        semester: semsetr,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadsemesterfeedetails(
    academicyear: any,
    semsetr: any,
    paymentstatus: any,
    startdate: any,
    enddate: any,
    programyear: any,
    program: any,
    college: any,
    degree: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/TransactionReport",
      {
        academicYear: [academicyear],
        semester: [semsetr],
        paymentStatus: paymentstatus,
        startDate: startdate,
        endDate: enddate,
        programmeyear: [programyear],
        programmes: [program],
        colleges: [college],
        degree: [degree]
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadsemesterfeedetailshistory(
    academicyear: any,
    semsetr: any,
    paymentstatus: any,
    startdate: any,
    enddate: any,
    programyear: any,
    program: any,
    college: any,
    degree: any,

  ) {
    return this.http.post(
      environment.endpoint + "/history/download/StudentTransactionReportHistory",
      {
        academicYear: [academicyear],
        semester: [semsetr],
        paymentStatus: paymentstatus,
        startDate: startdate,
        endDate: enddate,
        programmeyear: [programyear],
        programmes: [program],
        colleges: [college],
        degree: [degree]
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadsupply(
    type:any,
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any,
    college:any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/"+type+"StudentDetails",
      {
        degree: degree,
        program: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
        colleges:college
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadsupplyreevalpg(
    degree: any,
    program: any,
    semetsre: any,
    programyera: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/pgSupplyReEvaluation",
      {
        degree: degree,
        program: program,
        semster: semetsre,
        programmeYear: programyera,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadfeedbackstudentwise(
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
    course: any,
    degree: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/feedbackReport",
      {
        program: program,
        semster: semester,
        programmeYear: programyear,
        academicyear: academicyear,
        courses: course,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadfeedback(
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    design: any,
    degree: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateFeedbackConsolidatedPdf",
      {
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeYear: programyear,
        semster: semester,
        staff: staff,
        designation: design,
        degree: degree
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadinternalmarksrep(
    college: any,
    degree: any,
    academicyear: any,
    course: any,
    program: any,
    programyear: any,
    semester: any,
    staff: any,
    section: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateInternalPdf",
      {
        colleges: college,
        degree: degree,
        academicYear: academicyear,
        courses: course,
        programmes: program,
        programmeyear: programyear,
        semster: semester,
        staff: staff,
        section: section
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadgradereport(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
    exam: any,
    course: any,
    regtype: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/gradeReport.xlsx",
      {
        colleges: college,
        degree: degree,
        programmes: program,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        registrationType: regtype
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadteachercourse(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/teachersCourseReport",
      {
        colleges: college,
        degree: degree,
        program: program,
        semster: semester,
        programmeYear: programyear,
        academicyear: academicyear
      },
      { responseType: "blob" as "json" }
    );
  }


  downloaddeanapproval(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/deanApprovedReport.xlsx",
      {
        college: college,
        degree: degree,
        programme: program,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadassistantregistrar(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/assistantRegistrarApprovedReport",
      {
        college: college,
        degree: degree,
        programme:program,  
        semester:semester,
        programmeYear:programyear,
        academicYear: academicyear
      },
      { responseType: "blob" as "json" }
    );
  }
  downloadassisntantcontroller(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/assistantComptrollarApprovedReport",
      {
        college: college,
        degree: degree,
        programme:program,  
        semester:semester,
        programmeYear:programyear,
        academicYear: academicyear
      },
      { responseType: "blob" as "json" }
    );
  }
  downloadlibapproval(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/libraryApprovedReport.xlsx",
      {
        college: college,
        degree: degree,
        programme: program,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadhostelconfiguration(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/HostelConfigurationDetails.xlsx",
      {
        college: college,
        degree: degree,
        programme: program,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadhostelapproval(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/hostelApprovedReport.xlsx",
      {
        college: college,
        degree: degree,
        programme: program,
        semester: semester,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadcreteriareport(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    academicyear: any,
    cgpa: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/criteriaReport",
      {
        colleges: college,
        degree: degree,
        program: program,
        semster: semester,
        programmeYear: programyear,
        academicyear: academicyear,
        exCGPA: cgpa
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadalluminireport(
    college: any,
    degree: any,
    program: any,
    academicyear: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/aluminiReport",
      {
        colleges: college,
        degree: degree,
        program: program,
        academicyear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadstaffdetails(
    college: any,
    degree: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/staffDetails.xlsx",
      {
        colleges: college,
        degree: degree,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadcomplaintreport(
    college: any,
    degree: any,
    program: any,
    semester: any,
    programyear: any,
    startdate: any,
    enddate: any,
    academicyear: any,
    complaintype: any
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/ComplainReport",
      {
        college: college,
        degree: degree,
        programme: program,
        semester: semester,
        programmeyear: programyear,
        exStartDate: startdate,
        exEndDate: enddate,
        academicYear: academicyear,
        complaintStatus: complaintype
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadauditlogreport(
    privilagesmodule: any,
    startdate: any,
    enddate: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/auditLogReport",
      {
        privilegesModulesId: privilagesmodule,
        startDate: startdate,
        endDate: enddate
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadabacreport(
    startdate: any,
    enddate: any,
    degree: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/ABDACFeeReport",
      {
        exStartDate: startdate,
        exEndDate: enddate,
        degree: degree,
      },
      { responseType: "blob" as "json" }
    );
  }
  downloadabacreport1(
    startdate: any,
    enddate: any,
    degree: any,
    studentid:any

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/ABDACFeeReportStudentWise",
      {
        exStartDate: startdate,
        exEndDate: enddate,
        degree: degree,
        studentId:studentid
    
      },
      { responseType: "blob" as "json" }
    );
  }
  downloaddirectpayment(
    startdate: any,
    enddate: any,
    degree: any,


  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/DirectPaymentDetailsReport",
      {
        startDate: startdate,
        endDate: enddate,
        degree: degree,
      },
      { responseType: "blob" as "json" }
    );
  }


  downloadaccesslogreport(

    startdate: any,
    enddate: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/accessLogReport",
      {
        startDate: startdate,
        endDate: enddate
      },
      { responseType: "blob" as "json" }
    );
  }
  downloadgradelogreport(

    startdate: any,
    enddate: any,

  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/registrationLogReport",
      {
        startDate: startdate,
        endDate: enddate
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadgradepoint(
    studentid: any,
  ) {
    return this.http.post(
      environment.endpoint + "/excel/download/gradeReportDetails.xlsx",
      {
        exStudentId: studentid,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadCourseReceipt(
    academicyear: any,
    semester: any,
    programyear: any,
    studentid: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/registerCoursesPDF",
      {
        academicYear: academicyear,
        semester: semester,
        programmeYear: programyear,
        studentId: studentid
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadDegreeCertificate(
    college: any,
    degree: any,
    program: any,
    studentid: any,
    academicyear: any,
    user: any,
    dateinenglish: any,
    dateink: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateDegreePdf",
      {
        collegesid: college,
        degreeid: degree,
        programmesid: program,
        studentid: studentid,
        enrollmentYear: academicyear,
        user: user,
        dateinEnglish: dateinenglish,
        dateinKannada: dateink,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadCharacterCertificate(
    degrreid: any,
    programid: any,
    college: any,
    academic: any,
    user: any,
    scrutinize: any,
    studentid: any,
    dateinenglish: any,
    dateink: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateCharacterCertificateDocx",
      {
        degreeid: degrreid,
        programmesid: programid,
        collegesid: college,
        enrollmentYear: academic,
        user: user,
        scrutinize: scrutinize,
        studentid: studentid,
        dateOfDeclaration: dateinenglish,
        dateinKannada: dateink,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadCharacterCertificatepg(
    user: any,
    studentid: any,
    dateinenglish: any,
    dateink: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generatePGCharacterCertificateDocx",
      {
        user: user,
        studentid: studentid,
        dateOfDeclaration: dateinenglish,
        dateinKannada: dateink,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadDegreeCompletion(
    degrreid: any,
    programid: any,
    college: any,
    academic: any,
    user: any,
    scrutinize: any,
    studentid: any,
    dateinenglish: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateDegreeCompletionCertificateDocx",
      {
        degreeid: degrreid,
        programmesid: programid,
        collegesid: college,
        enrollmentYear: academic,
        user: user,
        scrutinize: scrutinize,
        studentid: studentid,
        dateOfDeclaration: dateinenglish,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload registrationcrd
  downloadBonafideCertificate(
    degrreid: any,
    programid: any,
    college: any,
    academic: any,
    user: any,
    scrutinize: any,
    studentid: any,
    dateinenglish: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateBonafideCertificateDocx",
      {
        degreeid: degrreid,
        programmesid: programid,
        collegesid: college,
        enrollmentYear: academic,
        user: user,
        scrutinize: scrutinize,
        studentid: studentid,
        dateOfDeclaration: dateinenglish,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload study certificate
  downloadStudyCertificate(
    degrreid: any,
    programid: any,
    college: any,
    academic: any,
    user: any,
    scrutinize: any,
    studentid: any,
    dateinenglish: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateStudyCertificateDocx",
      {
        degreeid: degrreid,
        programmesid: programid,
        collegesid: college,
        enrollmentYear: academic,
        user: user,
        scrutinize: scrutinize,
        studentid: studentid,
        dateOfDeclaration: dateinenglish,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadMastersheetug(
    college: any,
    degrreid: any,
    programid: any,
    studentid: any,
    academic: any,
    user: any,
   
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateMasterSheetForUg",
      {
        colleges: college,
    degree:degrreid,
    programmes: programid,
    exStudentId: studentid,
    academicYear: academic,
    user: user
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload progress report
  downloadProgreespdfall(
    college: any,
    semester: any,
    degree: any,
    program: any,
    exam: any,
    programyear: any,
    academicyear: any
  ) {
    return this.http.post(
      environment.endpoint + "/postexam/generateAllsemesterProgessReportPdf",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
      },
      { responseType: "blob" as "json" }
    );
  }

  downloadfeereceipt(
    orderid: any,

  ) {
    return this.http.post(
      environment.endpoint + "/report/generatefeeReceiptsPDF",
      {
        orderid: orderid,

      },
      { responseType: "blob" as "json" }
    );
  }


  //dowload remuneration
  downloadProgressdocx(
    college: any,
    semester: any,
    degree: any,
    program: any,
    course: any,
    exam: any,
    programyear: any,
    academicyear: any,
    section: any,
    user: any,
    regtype: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateGradeReportDocx",
      {
        colleges: college,
        semester: semester,
        degree: degree,
        programmes: program,
        courses: course,
        examAnnouncement: exam,
        programmeYear: programyear,
        academicYear: academicyear,
        sectionid: section,
        user: user,
        registrationType: regtype,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload examtimetable
  downloadmarksreport(barcode: any) {
    return this.http.post(
      environment.endpoint + "/report/generateMarksPdf",
      {
        barcode: barcode,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload examattendece
  downloadExamattendance(
    academicyear: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    regtype: any,
    room: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateAttendenceSheet",
      {
        academicYear: academicyear,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        registrationType: regtype,
        roomId: room,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload examattendece
  getkeyindexing(degree: any, academic: any, exam: any, course: any) {
    return this.http.post(
      environment.endpoint + "/report/generateKeyAnswerPdf",
      {
        degree: degree,
        academicYear: academic,
        examAnnouncement: exam,
        courses: course,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload QP
  generatequestionpaperpdf(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    version: any,
    time: any,
    qptype: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateQuestionPaperPdf",
      {
        degree: degree,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        version: version,
        time: time,
        questionPaperType: qptype,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload QP
  generatequestionpaperdescpdf(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    time: any,
    qptype: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateDescriptiveQPPdf",
      {
        degree: degree,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        time: time,
        questionPaperType: qptype,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload QP
  generatequestionpaperdocx(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    version: any,
    time: any,
    qptype: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateQuestionPaperdocx",
      {
        degree: degree,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        version: version,
        time: time,
        questionPaperType: qptype,
      },
      { responseType: "blob" as "json" }
    );
  }
  generatequestionpaperdescdocx(
    degree: any,
    academicyear: any,
    exam: any,
    course: any,
    time: any,
    qptype: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateDescriptiveQPdocx",
      {
        degree: degree,
        academicYear: academicyear,
        examAnnouncement: exam,
        courses: course,
        time: time,
        questionPaperType: qptype,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload examattendece
  getindexing(degree: any, academic: any, exam: any, course: any) {
    return this.http.post(
      environment.endpoint + "/report/generateIndexingPdf",
      {
        degree: degree,
        academicYear: academic,
        examAnnouncement: exam,
        courses: course,
      },
      { responseType: "blob" as "json" }
    );
  }

  //dowload examattendece
  downloadbarcodesheet(
    academicyear: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    regtype: any,
    room: any
  ) {
    return this.http.post(
      environment.endpoint + "/report/generateBarcodeSheet",
      {
        academicYear: academicyear,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        registrationType: regtype,
        roomId: room,
      },
      { responseType: "blob" as "json" }
    );
  }

  changeexamhall(
    academic: any,
    program: any,
    college: any,
    degree: any,
    course: any,
    exam: any,
    studentid: any,
    studentto: any,
    room: any,
    retype: any,
    user: any,
    coursecode: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/changeExamHall",
      {
        academicYear: academic,
        programmes: program,
        colleges: college,
        degree: degree,
        courses: course,
        examAnnouncement: exam,
        exStudentIdFrom: studentid,
        exStudentIdTo: studentto,
        roomId: room,
        registrationType: retype,
        user: user,
        exCoursesCode: coursecode,
      },
      { observe: "response" }
    );
  }

  //qppattern
  qppattern(
    type: any,
    academicyear: any,
    degree: any,
    exam: any,
    program: any,
    questionptype: any,
    qptopic: any,
    startno: any,
    endno: any,
    eachqmark: any,
    noofqueston: any,
    active: any,
    id: any,
    user: any
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/masters/QuestionPaperPattern/" + type,
      {
        academicYear: academicyear,
        degree: degree,
        examAnnouncement: exam,
        programme: program,
        questionPaperType: questionptype,
        questionPaperTopic: qptopic,
        exStartNo: startno,
        exEndNo: endno,
        exEachQuestionMark: eachqmark,
        exNoOfQuestion: noofqueston,
        exIsActive: active,
        id: id,
        user: user,
      },
      { observe: "response" }
    );
  }

  convocationReport(academicyear: any, degree: any,college:any) {
    return this.http.post(
      environment.endpoint +
      "/convocation/download/studentConvocationDetails.xlsx",
      {
        academicYearID: academicyear,
        degreeid: degree,
        colleges:college
      },
      { responseType: "blob" }
    );
  }

  reconciliationReport(startdate: any, enddate: any) {
    return this.http.post(
      environment.endpoint +
      "/excel/collection/settlement",
      {
        startDate: startdate,
        endDate: enddate,
      },
      { responseType: "blob" }
    );
  }


  submitfeedback(
    programes: any,
    courses: any,
    programeyear: any,
    semester: any,
    acdemic: any,
    staff: any,
    aboutfeedback: any,
    designation: any,
    student: any,
    active: any,
    degree: any,
    remark: any,

  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/feedback/sumbit",
      {
        programmes: programes,
        courses: courses,
        programmeYear: programeyear,
        semster: semester,
        academicYear: acdemic,
        staff: staff,
        details: aboutfeedback,
        designation: designation,
        studentId: student,
        exIsActive: active,
        degree: degree,
        exRemark: remark
      },
      { observe: "response" }
    );
  }

  getModules(roleid: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/master/getPrivilegesAndModuleName",
      {
        role: roleid,
      },
      { observe: "response" }
    );
  }

  loadAlert(
    exRollNo: any,
    colleges: any,
    programeYear: any,
    academicYear: any,
    semester: any,
  ): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/mobile/loadAlert",
      {
        studentId:exRollNo,
    colleges:colleges,
    programeYear:programeYear,
    academicYear:academicYear,
    semester:semester
      },
      { observe: "response" }
    );
  }

  updateAlert(exRollNo: any, alertId: any): Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/mobile/updateAlert",
      {
        studentId:exRollNo,
        alertId:alertId
      },
      { observe: "response" }
    );
  }
}
